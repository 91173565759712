import React, { useState } from "react";
import DownloadSvg from "../../../assets/Download.svg";
import AsyncModal from "../AsyncModal";

function ExportReportButton() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onClose = () => {
        setIsModalOpen(false);
    };

    const onClick = () => {
        setIsModalOpen(true);
    };

    return (
        <div>
            <img
                src={DownloadSvg}
                alt="Download"
                onClick={onClick}
                className="w-[25px] cursor-pointer"
            />
            {isModalOpen && <AsyncModal onClose={onClose} />}
        </div>
    );
}

export default ExportReportButton;
