import React from "react";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

function StateSelectInput({
    name,
    label,
    options,
    currentValue,
    onChange,
    className,
    disabled,
}) {
    const labelId = `${name}-label`;

    const menuItems = options.map((option) => {
        const value = typeof option === "string" ? option : option.value;
        const itemLabel = typeof option === "string" ? option : option.label;

        return (
            <MenuItem key={value} value={value}>
                {itemLabel}
            </MenuItem>
        );
    });

    return (
        <FormControl name={name} className={className} variant="standard">
            <InputLabel
                id={labelId}
                shrink={!!currentValue}
                sx={{
                    "&.Mui-focused": { color: "#8D58FF" },
                }}
            >
                {label}
            </InputLabel>
            <Select
                labelId={labelId}
                id={name}
                label={label}
                disabled={disabled}
                onChange={(event) => onChange(event.target.value)}
                value={currentValue}
                sx={{
                    "&:after": {
                        borderBottomColor: "#8D58FF",
                    },
                }}
            >
                {menuItems}
            </Select>
        </FormControl>
    );
}

StateSelectInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
            }),
        ]).isRequired
    ).isRequired,
    currentValue: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

StateSelectInput.defaultProps = {
    className: "",
    disabled: false,
};

export default StateSelectInput;
