import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "../../../../../Button";
import EntriesStartPicker from "./EntriesStartPicker";
import { useUpdateEntriesStartMutation } from "../../../../../../redux/slices/adminApi";

function UpdateEntriesStart({
    entriesStart,
    validUpUntil,
    businessEntity,
    provider,
    setEmailModalData,
}) {
    const [entriesStartDate, setEntriesStartDate] = useState(entriesStart);

    const onChange = (newEntriesStartDate) => {
        setEntriesStartDate(newEntriesStartDate);
    };

    const [updateEntriesStart] = useUpdateEntriesStartMutation();

    const onClick = async () => {
        try {
            await updateEntriesStart({
                providerId: businessEntity.provider_id,
                businessEntityId: businessEntity.id,
                body: {
                    entries_start: entriesStartDate,
                },
            });
            setEmailModalData({
                validUpUntil,
                entriesStart: entriesStartDate,
                provider,
                businessEntity,
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="flex flex-row items-center">
            <EntriesStartPicker value={entriesStartDate} onChange={onChange} />
            <div className="ml-[10px]">
                <Button
                    className="rounded-full w-fit px-[10px] py-[5px]"
                    onClick={onClick}
                    disabled={entriesStart === entriesStartDate}
                >
                    Update
                </Button>
            </div>
        </div>
    );
}

UpdateEntriesStart.propTypes = {
    entriesStart: PropTypes.string.isRequired,
    validUpUntil: PropTypes.string.isRequired,
    businessEntity: PropTypes.object.isRequired,
    provider: PropTypes.object.isRequired,
    setEmailModalData: PropTypes.func.isRequired,
};

export default UpdateEntriesStart;
