/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import RefreshCWSvg from "../../../../assets/RefreshCW";
import usePlaidLink from "../../../../hooks/usePlaidLink";

function RestoreConnectionMenuItem({ plaidItemId, closeMenu }) {
    const { openPlaidLink } = usePlaidLink(plaidItemId);

    const handleRestoreConnection = () => {
        closeMenu();
        openPlaidLink();
    };

    return (
        <MenuItem
            key="restore_connection"
            value="restore_connection"
            className="flex flex-row items-center gap-[8px] hover:text-flychainPurple-light"
            onClick={handleRestoreConnection}
        >
            <RefreshCWSvg fill="currentColor" width={16} height={16} />
            Restore Connection
        </MenuItem>
    );
}

RestoreConnectionMenuItem.propTypes = {
    plaidItemId: PropTypes.string.isRequired,
    closeMenu: PropTypes.func.isRequired,
};

export default RestoreConnectionMenuItem;
