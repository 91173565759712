import React from "react";
import MainBenefitsCard from "../../MainBenefitsCard";
import StartupsSvg from "./Startups.svg";

function Startups() {
    return (
        <MainBenefitsCard
            imageSrc={StartupsSvg}
            title="Over 200,000 Startups Use Mercury"
            body="Mercury enables over 200,000 startups of all sizes to operate at their highest level with best-in-class banking."
        />
    );
}

export default Startups;
