import React from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { getDateRangeForQuarter } from "../utils";
import {
    getButtonClassName,
    handleButtonMouseEnter,
    handleButtonMouseLeave,
} from "../../DatePicker/utils";

function QuarterButton({
    year,
    quarter,
    onClick,
    maxDate,
    validUpUntil = maxDate,
    setHoverUnvalidatedToggle,
}) {
    const { startDate, endDate } = getDateRangeForQuarter(
        year,
        quarter,
        maxDate
    );

    const parsedMaxDate = dayjs(maxDate);
    const parsedValidUpUntil = dayjs(validUpUntil);
    const parsedQuarterStart = dayjs(startDate);
    const parsedQuarterEnd = dayjs(endDate);

    const disabled = parsedQuarterStart.isAfter(parsedMaxDate, "day");
    const unvalidated = parsedQuarterEnd.isBetween(
        parsedValidUpUntil,
        parsedMaxDate,
        "day",
        "(]" // exclusive of the start date and inclusive of the end date
    );

    const handleClick = () => {
        if (!disabled) {
            onClick(quarter);
        }
    };

    return (
        <div
            className={`p-[10px] shadow-[0px_2px_8px_0px_#0000001f] rounded-xl w-[50px] text-[14px] font-medium text-center ${getButtonClassName(
                disabled,
                unvalidated
            )}`}
            onClick={handleClick}
            onMouseEnter={
                setHoverUnvalidatedToggle
                    ? () =>
                          handleButtonMouseEnter(
                              disabled,
                              unvalidated,
                              setHoverUnvalidatedToggle
                          )
                    : undefined
            }
            onMouseLeave={
                setHoverUnvalidatedToggle
                    ? () =>
                          handleButtonMouseLeave(
                              disabled,
                              unvalidated,
                              setHoverUnvalidatedToggle
                          )
                    : undefined
            }
        >
            {`Q${quarter + 1}`}
        </div>
    );
}

QuarterButton.propTypes = {
    year: PropTypes.number.isRequired,
    quarter: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    maxDate: PropTypes.string.isRequired,
    validUpUntil: PropTypes.string,
    setHoverUnvalidatedToggle: PropTypes.func,
};

QuarterButton.defaultProps = {
    validUpUntil: undefined,
    setHoverUnvalidatedToggle: undefined,
};

export default QuarterButton;
