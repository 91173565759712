/* eslint-disable camelcase */
import React, { useState } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import ValuationExplanationCard from "./ValuationExplanationCard";
import {
    getCurrencyString,
    getFriendlyString,
    MMMM_D_YYYY_FORMAT,
} from "../../../utils";
import EBITDAMultiplesModal from "./EBITDAMultiplesModal";
import ClockSvg from "../../../assets/Clock";
import BadgeDollarSignSvg from "../../../assets/BadgeDollarSign";
import CalculatorSvg from "../../../assets/Calculator";
import CalendarSvg from "../../../assets/Calendar";
import CopyXSvg from "../../../assets/CopyX";
import TrendingUpSvg from "../../../assets/TrendingUp";
import Button from "../../Button";
import { MERTZ_TAGGART_INTRO_CALL_URL } from "../ValuationEstimate";

function renderValuationRange(valuation_cents) {
    return (
        <div className="flex flex-col gap-y-[20px] mt-[15px] mx-[6px]">
            <div className="text-2xl font-bold">
                {`${getCurrencyString(
                    valuation_cents[0]
                )} to ${getCurrencyString(valuation_cents[1])}`}
            </div>
            <div className="text-sm font-normal text-flychainGray">
                Finally, we apply your multiples to the annualized EBITDA to get
                your Valuation Range. For a full valuation, we recommend
                scheduling a free valuation with our M&A partner.
            </div>
            <Button
                onClick={() =>
                    window.open(MERTZ_TAGGART_INTRO_CALL_URL, "_blank")
                }
                className="inline-block rounded-full px-[16px] py-[12px] w-fit mt-[10px] bg-flychainPurple-light text-white"
            >
                Schedule a Free Valuation
            </Button>
        </div>
    );
}

function SingleEntityValuationExplanation({ valuationEntity }) {
    const {
        business_entity,
        valuation_start_date,
        books_end_date,
        net_profit_cents,
        ebitda_cents,
        annualized_ebitda_cents,
        multiple_range,
        valuation_cents,
    } = valuationEntity;

    const startDate = dayjs(valuation_start_date);
    const endDate = dayjs(books_end_date);
    const timePeriodString = `${startDate.format(
        MMMM_D_YYYY_FORMAT
    )} to ${endDate.format(MMMM_D_YYYY_FORMAT)}`;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const onClick = () => setIsModalOpen(true);
    const onClose = () => setIsModalOpen(false);

    const ebitdaMultiplesDescription = (
        <div>
            Your EBITDA multiples are based on market standards and can vary
            based on various factors explained{" "}
            <span
                onClick={onClick}
                className="cursor-pointer text-flychainPurple-light"
            >
                here
            </span>
            . To get more accurate EBITDA multiples, we recommend scheduling a
            free valuation with our M&A partner.
        </div>
    );

    return (
        <>
            <div className="flex flex-col gap-y-[50px] w-full">
                <div className="flex flex-row justify-center w-full gap-x-[50px]">
                    <ValuationExplanationCard
                        icon={
                            <ClockSvg fill="#8D58FF" height={28} width={28} />
                        }
                        className="flex flex-col w-[40%] p-[24px] bg-flychainOffWhite"
                        title="1. Get the time period considered"
                    >
                        <div className="flex flex-col gap-y-[20px] mt-[15px] mx-[6px]">
                            <div className="text-2xl font-bold">
                                {timePeriodString}
                            </div>
                            <div className="text-sm font-normal text-flychainGray">
                                If we have twelve months or more of your
                                financials on our platform, we&apos;ll use the
                                trailing twelve months. Otherwise, we&apos;ll
                                use as many months as we have.
                            </div>
                        </div>
                    </ValuationExplanationCard>
                    <ValuationExplanationCard
                        icon={
                            <BadgeDollarSignSvg
                                fill="#8D58FF"
                                height={28}
                                width={28}
                            />
                        }
                        className="flex flex-col w-[40%] p-[24px] bg-flychainOffWhite"
                        title="2. Get the Net Profit for the entity"
                    >
                        <div className="flex flex-col gap-y-[20px] mt-[15px] mx-[6px]">
                            <div className="text-2xl font-bold">
                                {getCurrencyString(net_profit_cents)}
                            </div>
                            <div className="text-sm font-normal text-flychainGray">
                                Net profit is calculated by subtracting all
                                expenses - including COGS, Operating Expenses,
                                and Other Expenses - from your revenue.
                            </div>
                        </div>
                    </ValuationExplanationCard>
                </div>
                <div className="flex flex-row justify-center w-full gap-x-[50px]">
                    <ValuationExplanationCard
                        icon={
                            <CalculatorSvg
                                fill="#8D58FF"
                                height={28}
                                width={28}
                            />
                        }
                        className="flex flex-col w-[40%] p-[24px] bg-flychainOffWhite"
                        title="3. Calculate EBITDA for the entity"
                    >
                        <div className="flex flex-col gap-y-[20px] mt-[15px] mx-[6px]">
                            <div className="text-2xl font-bold">
                                {getCurrencyString(ebitda_cents)}
                            </div>
                            <div className="text-sm font-normal text-flychainGray">
                                EBITDA is calculated by adding back any expenses
                                related to interest, taxes, depreciation, and
                                amortization.
                            </div>
                        </div>
                    </ValuationExplanationCard>
                    <ValuationExplanationCard
                        icon={
                            <CalendarSvg
                                fill="#8D58FF"
                                height={28}
                                width={28}
                            />
                        }
                        className="flex flex-col w-[40%] p-[24px] bg-flychainOffWhite"
                        title="4. Annualize the EBITDA"
                    >
                        <div className="flex flex-col gap-y-[20px] mt-[15px] mx-[6px]">
                            <div className="text-2xl font-bold">
                                {getCurrencyString(annualized_ebitda_cents)}
                            </div>
                            <div className="text-sm font-normal text-flychainGray">
                                If the time period considered is less than 12
                                months, the EBITDA is annualized.
                            </div>
                        </div>
                    </ValuationExplanationCard>
                </div>
                <div className="flex flex-row justify-center w-full gap-x-[50px]">
                    <ValuationExplanationCard
                        icon={
                            <CopyXSvg fill="#8D58FF" height={28} width={28} />
                        }
                        className="flex flex-col w-[40%] p-[24px] bg-flychainOffWhite"
                        title="5. Get the EBITDA multiples"
                    >
                        <div className="flex flex-col gap-y-[20px] mt-[15px] mx-[6px]">
                            <div className="flex flex-col justify-start">
                                <div className="text-lg text-flychainGray">
                                    Specialty:
                                </div>
                                <div className="text-2xl font-bold">
                                    {getFriendlyString(
                                        business_entity.specialty
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col justify-start">
                                <div className="text-lg text-flychainGray">
                                    Multiple Range:
                                </div>
                                <div className="text-2xl font-bold">
                                    {`${multiple_range[0]}x to ${multiple_range[1]}x`}
                                </div>
                            </div>
                            <div className="text-sm font-normal text-flychainGray">
                                {ebitdaMultiplesDescription}
                            </div>
                        </div>
                    </ValuationExplanationCard>
                    <ValuationExplanationCard
                        icon={
                            <TrendingUpSvg
                                fill="#8D58FF"
                                height={28}
                                width={28}
                            />
                        }
                        className="flex flex-col w-[40%] p-[24px] bg-[#eaf2e9]"
                        title="6. Calculate the Valuation Range"
                    >
                        {renderValuationRange(valuation_cents)}
                    </ValuationExplanationCard>
                </div>
            </div>
            {isModalOpen && <EBITDAMultiplesModal onClose={onClose} />}
        </>
    );
}

SingleEntityValuationExplanation.propTypes = {
    valuationEntity: PropTypes.object.isRequired,
};

export default SingleEntityValuationExplanation;
