import React from "react";
import LegendDot from "../Legend/LegendDot";
import LegendLine from "../Legend/LegendLine";

function NetCashFlowGraphLegend() {
    return (
        <div className="flex flex-row justify-center items-center gap-x-[20px]">
            <div className="flex flex-row items-center gap-x-[5px]">
                <LegendDot fill="#8D58FF" />
                <div className="text-[11px] font-[600] text-flychainPurple-light">
                    Inflows
                </div>
            </div>
            <div className="flex flex-row items-center gap-x-[5px]">
                <LegendDot fill="#757575" />
                <div className="text-[11px] font-[600] text-flychainGray">
                    Outflows
                </div>
            </div>
            <div className="flex flex-row items-center gap-x-[5px]">
                <LegendDot fill="#F8CE39" />
                <div className="text-[11px] font-[600] text-flychainYellow">
                    Net Cash Flow
                </div>
            </div>
            <div className="flex flex-row items-center gap-x-[5px]">
                <LegendLine fill="#757575" dashed />
                <div className="text-[11px] font-[600] text-flychainGray">
                    Unreviewed Data
                </div>
            </div>
        </div>
    );
}

export default NetCashFlowGraphLegend;
