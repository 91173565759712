import React from "react";
import PropTypes from "prop-types";
import PERIODS from "./constants";

const displayValue = (value) => {
    switch (value) {
        case PERIODS.MONTH:
            return "Month";
        case PERIODS.DAY:
            return "Day";
        default:
            throw new Error("Unknown period");
    }
};

function Period({ value, isSelected, onClick }) {
    const handleClick = () => {
        onClick(value);
    };

    return (
        <div
            className={`hover:opacity-[60%] font-medium text-center bg-white shadow-md cursor-pointer text-[14px] p-[10px] rounded-[12px] w-[80px] ${
                isSelected && "text-flychainPurple-light"
            }`}
            onClick={handleClick}
        >
            {displayValue(value)}
        </div>
    );
}

Period.propTypes = {
    value: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Period;
