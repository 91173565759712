/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../utils";
import UnverifiedDataWarningDropdown from "../../UnverifiedDataWarning/UnverifiedDataWarningDropdown";

function MonthlyGraphTooltip({ active, payload, validUpUntil }) {
    if (!active || payload.length === 0) {
        return null;
    }

    const dataPoint = payload[0].payload;
    const { month, amount_cents, unverified_amount_cents } = dataPoint;
    const unverifiedDataPoint =
        amount_cents === undefined && unverified_amount_cents !== undefined;

    return (
        <div className="flex flex-col w-min">
            <div
                className={`w-fit shadow-md bg-white flex flex-col p-[12px] gap-y-[10px] ${
                    unverifiedDataPoint
                        ? "rounded-t-[10px] min-w-[200px]"
                        : "rounded-[10px]"
                }`}
            >
                <div className="text-[12px] font-normal text-flychainGray">
                    {month}
                </div>
                <div className="text-[18px] font-semibold">
                    {getCurrencyString(
                        unverifiedDataPoint
                            ? unverified_amount_cents
                            : amount_cents
                    )}
                </div>
            </div>
            {unverifiedDataPoint && (
                <UnverifiedDataWarningDropdown validUpUntil={validUpUntil} />
            )}
        </div>
    );
}

MonthlyGraphTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(
        PropTypes.shape({
            payload: PropTypes.shape({
                month: PropTypes.string.isRequired,
                amount_cents: PropTypes.number,
                unverified_amount_cents: PropTypes.number,
            }).isRequired,
        }).isRequired
    ),
    validUpUntil: PropTypes.string.isRequired,
};

MonthlyGraphTooltip.defaultProps = {
    active: false,
    payload: [],
};

export default MonthlyGraphTooltip;
