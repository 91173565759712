import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
    ResponsiveContainer,
    BarChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Bar,
    Legend,
} from "recharts";
import SharedDefsContextProvider, {
    useSharedDefs,
} from "../SharedDefsContextProvider";
import { getCurrencyString } from "../../../utils";
import {
    tickStyle,
    formatMonthYearStringForXAxis,
    splitMetric,
    VALID_DATE_FORMATS,
} from "../utils";
import MonthlyGraphTooltip from "./MonthlyGraphTooltip";
import MonthlyGraphLegend from "./MonthlyGraphLegend";
import useGetFinancialReportsValidUpUntilQuery from "../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";

function MonthlyGraphContent({ data, ledgerName }) {
    const defs = useSharedDefs();
    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    // Get the most recent 12 months
    const dataPoints = useMemo(() => {
        if (data.length <= 12) {
            return data;
        }
        return data.slice(-12);
    }, [data]);

    if (!validUpUntilData) return null;

    const validUpUntil = validUpUntilData.valid_up_until;

    const validBars = dataPoints.map((point) => {
        const amountCents = splitMetric(
            point.amount_cents,
            point.month,
            VALID_DATE_FORMATS.MMMM_YYYY,
            validUpUntil
        );
        return {
            ...point,
            amount_cents: amountCents.verified,
            unverified_amount_cents: amountCents.unverified,
        };
    });

    return (
        <ResponsiveContainer width="100%" height={200}>
            <BarChart data={validBars}>
                <CartesianGrid
                    stroke="#CCCCCC"
                    strokeWidth={1}
                    strokeDasharray="3 3"
                    horizontal
                    vertical={false}
                />
                <XAxis
                    dataKey="month"
                    tick={tickStyle}
                    tickFormatter={formatMonthYearStringForXAxis}
                />
                <YAxis
                    tickFormatter={getCurrencyString}
                    width={100}
                    tick={tickStyle}
                />
                <Tooltip
                    content={
                        <MonthlyGraphTooltip validUpUntil={validUpUntil} />
                    }
                />
                <Legend
                    content={<MonthlyGraphLegend ledgerName={ledgerName} />}
                />
                {defs}
                <Bar
                    dataKey="amount_cents"
                    stackId="stack"
                    barSize={24}
                    fill="url(#flychainPurple)"
                />
                <Bar
                    dataKey="unverified_amount_cents"
                    stackId="stack"
                    barSize={24}
                    fill="url(#flychainPurple)"
                    fillOpacity={0.4}
                    stroke="#8D58FF"
                    strokeWidth={1.5}
                    strokeDasharray="3 3"
                />
            </BarChart>
        </ResponsiveContainer>
    );
}

const dataProps = PropTypes.arrayOf(
    PropTypes.shape({
        month: PropTypes.string.isRequired,
        amount_cents: PropTypes.number.isRequired,
    }).isRequired
);

MonthlyGraphContent.propTypes = {
    data: dataProps.isRequired,
    ledgerName: PropTypes.string,
};

MonthlyGraphContent.defaultProps = {
    ledgerName: "",
};

function MonthlyGraph({ data, ledgerName }) {
    return (
        <SharedDefsContextProvider>
            <MonthlyGraphContent data={data} ledgerName={ledgerName} />
        </SharedDefsContextProvider>
    );
}

MonthlyGraph.propTypes = {
    data: dataProps.isRequired,
    ledgerName: PropTypes.string,
};

MonthlyGraph.defaultProps = {
    ledgerName: "",
};

export default MonthlyGraph;
