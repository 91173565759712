import { useParams } from "react-router-dom";
import { useGetFinancingReportForBusinessEntityQuery as useAdminGetFinancingReportForBusinessEntityQuery } from "../../../redux/slices/adminApi";
import { useGetFinancingReportForBusinessEntityQuery as useProviderGetFinancingReportForBusinessEntityQuery } from "../../../redux/slices/api";

const useGetFinancingReportForBusinessEntityQuery = (params) => {
    // Get the includeIncomplete flag from the function params
    const { businessEntityId } = params;

    const { providerId } = useParams();

    // If providerId is provided via function params or url params, use the admin query
    if (providerId) {
        return useAdminGetFinancingReportForBusinessEntityQuery({
            providerId,
            businessEntityId,
        });
    }

    // If providerId is not provided at all, use the provider query. Provider id will be passed in via the request's user context
    return useProviderGetFinancingReportForBusinessEntityQuery({
        businessEntityId,
    });
};

export default useGetFinancingReportForBusinessEntityQuery;
