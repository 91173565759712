import React from "react";
import PropTypes from "prop-types";

function LegendLine({ fill, dashed }) {
    return (
        <svg width="18" height="12">
            <line
                x1="1"
                y1="6"
                x2="17"
                y2="6"
                stroke={fill}
                strokeWidth="1.5"
                strokeDasharray={dashed ? "3 3" : "none"}
            />
        </svg>
    );
}

LegendLine.propTypes = {
    fill: PropTypes.string.isRequired,
    dashed: PropTypes.bool,
};

LegendLine.defaultProps = {
    dashed: false,
};

export default LegendLine;
