import React from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";
import SideBarAndContent from "./SideBarAndContent";
import RouterError from "../RouterError";
import Providers from "./Providers";
import Expenses from "../FinancialReports/Expenses";
import Revenue from "../FinancialReports/Revenue";
import MerchantDetailView from "../FinancialReports/Expenses/MerchantDetailView";
import ExpiredPlaidItems from "./ExpiredPlaidItems";
import BalanceSheet from "./FinancialReports/BalanceSheet";
import LedgerLines from "../FinancialReports/LedgerLines";
import IncomeStatement from "./FinancialReports/IncomeStatement";
import CashFlow from "./FinancialReports/CashFlow";
import TestUsers from "./TestUsers";
import FinancialReportsHome from "./FinancialReports/FinancialReportsHome";
import CollectionFlow from "./Financing/CollectionFlow";
import BusinessEntityWrapper from "../FinancialReports/BusinessEntityWrapper";
import DateRangeWrapper from "../FinancialReports/DateRangeWrapper";
import Capital from "../Capital";
import AdminAdvancedPaymentOnClaims from "./Capital/AdminAdvancedPaymentOnClaims";
import LineOfCredit from "../Capital/LineOfCredit";
import TermLoan from "../Capital/TermLoan";
import SBAPrograms from "../Capital/SBAPrograms";
import Banking from "../Banking";
import BillPay from "../Banking/BillPay";
import Treasury from "../Banking/Treasury";
import BookkeepingDates from "./BookkeepingDates";
import Preconditions from "./OnboardingFlow/Preconditions";
import Entities from "./OnboardingFlow/Entities";
import Mappings from "./OnboardingFlow/Mappings";
import Linking from "./OnboardingFlow/Linking";
import Check from "./OnboardingFlow/Check";
import Redirect from "../FinancialReports/Redirect";
import Valuation from "../Valuation";
import AdminSavings from "./AdminSavings";
import Taxes from "../Taxes";
import Settings from "../Settings";
import BusinessEntityCreator from "../BusinessEntity/BuisnessEntityCreator";
import QBORedirect from "../QBORedirect";

const router = createHashRouter([
    {
        path: "/",
        element: <SideBarAndContent />,
        errorElement: <RouterError />,
        children: [
            {
                path: "/providers",
                element: <Providers />,
            },
            {
                path: "/provider/:providerId",
                element: <Redirect />,
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <FinancialReportsHome />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/incomeStatement",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <IncomeStatement />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/incomeStatement/ledgers/:ledgerId",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <LedgerLines />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/cashFlow",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <CashFlow />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/cashFlow/ledgers/:ledgerId",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <LedgerLines />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/balanceSheet",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <BalanceSheet />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/balanceSheet/ledgers/:ledgerId",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <LedgerLines />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/revenue",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <Revenue />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/revenue/ledgers/:ledgerId",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <LedgerLines />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/expenses",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <Expenses />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/expenses/ledgers/:ledgerId",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <LedgerLines />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/expenses/merchants/:merchantId",
                element: (
                    <BusinessEntityWrapper>
                        <MerchantDetailView />
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/capital",
                element: <Capital />,
            },
            {
                path: "/provider/:providerId/capital/advancedPaymentOnClaims",
                element: <AdminAdvancedPaymentOnClaims />,
            },
            {
                path: "/provider/:providerId/capital/lineOfCredit",
                element: <LineOfCredit />,
            },
            {
                path: "/provider/:providerId/capital/termLoan",
                element: <TermLoan />,
            },
            {
                path: "/provider/:providerId/capital/sbaPrograms",
                element: <SBAPrograms />,
            },
            {
                path: "/provider/:providerId/taxes",
                element: <Taxes />,
            },
            {
                path: "/provider/:providerId/banking",
                element: <Banking />,
            },
            {
                path: "/provider/:providerId/banking/billPay",
                element: <BillPay />,
            },
            {
                path: "/provider/:providerId/banking/treasury",
                element: <Treasury />,
            },
            {
                path: "/provider/:providerId/valuation",
                element: <Valuation />,
            },
            {
                path: "/provider/:providerId/savings",
                element: <AdminSavings />,
            },
            {
                path: "/collectionFlow",
                element: <CollectionFlow />,
            },
            {
                path: "/ExpiredPlaidItems",
                element: <ExpiredPlaidItems />,
            },
            {
                path: "/testUsers",
                element: <TestUsers />,
            },
            {
                path: "/bookkeepingDates",
                element: <BookkeepingDates />,
            },
            {
                path: "/provider/:providerId/onboard",
                element: <Preconditions />, // see if provider is ready
            },
            {
                path: "/provider/:providerId/onboard/entities",
                element: <Entities />, // create/view entities
            },
            {
                path: "/provider/:providerId/onboard/mappings",
                element: <Mappings />, // determine mappings between accounts and entities
            },
            {
                path: "/provider/:providerId/onboard/linking",
                element: <Linking />, // connect instances to accounts
            },
            {
                path: "/provider/:providerId/onboard/check",
                element: <Check />, // automatic and manual checks to see if everything is in order
            },
            {
                path: "/provider/:providerId/settings",
                element: <Settings />,
            },
            {
                path: "/provider/:providerId/settings/createEntity",
                element: <BusinessEntityCreator />,
            },
            {
                path: "/qboRedirect",
                element: <QBORedirect />,
            },
        ],
    },
]);

function AdminDashboard() {
    return <RouterProvider router={router} />;
}

export default AdminDashboard;
