import { useState, useEffect } from "react";
import dayjs from "dayjs";

function useValidateDateRange(validUpUntilData, dateRange) {
    const [dateRangeUnvalidated, setDateRangeUnvalidated] = useState(false);

    useEffect(() => {
        if (validUpUntilData) {
            const parsedStartDate = dayjs(dateRange.startDate);
            const parsedEndDate = dayjs(dateRange.endDate);
            const parsedValidUpUntil = dayjs(validUpUntilData.valid_up_until);

            setDateRangeUnvalidated(
                parsedStartDate.isAfter(parsedValidUpUntil, "day") ||
                    parsedEndDate.isAfter(parsedValidUpUntil, "day")
            );
        }
    }, [validUpUntilData, dateRange]);

    return dateRangeUnvalidated;
}

export default useValidateDateRange;
