import React from "react";
import PropTypes from "prop-types";
import useGetBusinessEntitiesQuery from "../../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import SectionTitle from "../../../SectionTitle";
import BusinessEntity from "./BusinessEntity";

function Provider({ provider, setEmailModalData }) {
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId: provider.id,
        includeIncomplete: false,
    });

    return businessEntities ? (
        <div className="flex flex-col px-[15px] pt-[15px] pb-[10px] gap-y-[20px] border-[1px] rounded-xl shadow-lg">
            <div className="flex flex-row justify-between items-center gap-x-[10px]">
                <SectionTitle>{provider.legal_name}</SectionTitle>
                <div className="text-sm text-flychainGray">{provider.id}</div>
            </div>
            <div className="flex flex-col gap-y-[15px]">
                {businessEntities.map((businessEntity) => (
                    <div
                        key={businessEntity.id}
                        className="flex flex-col pt-[15px] border-t-[1px] border-gray-300"
                    >
                        <BusinessEntity
                            businessEntity={businessEntity}
                            provider={provider}
                            setEmailModalData={setEmailModalData}
                        />
                    </div>
                ))}
            </div>
        </div>
    ) : null;
}

Provider.propTypes = {
    provider: PropTypes.object.isRequired,
    setEmailModalData: PropTypes.func.isRequired,
};

export default Provider;
