import React, { useState, useCallback, useEffect } from "react";
import { isMobileOnly } from "react-device-detect";
import { Header, Subheading } from "../../../Text";
import Modal from "../../index";
import Button from "../../../Button";

function MobileModal() {
    const [showMobileModal, setShowMobileModal] = useState(false);

    useEffect(() => {
        if (isMobileOnly) {
            setShowMobileModal(true);
        }
    }, []);

    const onClose = useCallback(() => {
        setShowMobileModal(false);
    }, [setShowMobileModal]);

    return showMobileModal ? (
        <Modal onClose={onClose}>
            <div className="flex flex-col justify-start">
                <Header>Not optimized for mobile</Header>
                <Subheading>
                    This website is not currently optimized for mobile devices.
                    While this website will work on mobile, we recommend
                    accessing it on desktop or tablet instead.
                </Subheading>
                <div className="my-[20px]">
                    <Button onClick={onClose}>Proceed on mobile device</Button>
                </div>
            </div>
        </Modal>
    ) : null;
}

export default MobileModal;
