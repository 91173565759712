/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { getFinancialReportsValidUpUntil as getFinancialReportsValidUpUntilAdmin } from "../adminApi";
import { getFinancialReportsValidUpUntil } from "../api";
import resetProviderState from "../actions";
import { YYYY_MM_DD_FORMAT } from "../../../utils";

const getDateRangeFromValidUpUntil = (state, action) => {
    if (state.startDate && state.endDate) {
        return state;
    }
    const validUpUntilDate = dayjs(action.payload.valid_up_until);
    return {
        startDate: validUpUntilDate.startOf("month").format(YYYY_MM_DD_FORMAT),
        endDate: validUpUntilDate.format(YYYY_MM_DD_FORMAT),
    };
};

const initialState = {
    startDate: null,
    endDate: null,
};

const dateRangeSlice = createSlice({
    name: "dateRange",
    initialState,
    reducers: {
        updateDateRange(state, action) {
            return action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                getFinancialReportsValidUpUntilAdmin.matchFulfilled,
                getDateRangeFromValidUpUntil
            )
            .addMatcher(
                getFinancialReportsValidUpUntil.matchFulfilled,
                getDateRangeFromValidUpUntil
            )
            .addMatcher(
                (action) => action.type === resetProviderState.type,
                () => initialState
            );
    },
});

export const selectDateRange = (state) => state.dateRange;

export const { updateDateRange } = dateRangeSlice.actions;

export default dateRangeSlice.reducer;
