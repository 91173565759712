import React from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
    selectDateRange,
    updateDateRange,
} from "../../../redux/slices/dateRange";
import useGetRevenueByCategoryQuery from "../../../hooks/queriesAndMutations/useGetRevenueByCategoryQuery";
import useGetFinancialReportsValidUpUntilQuery from "../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import useValidateDateRange from "../../../hooks/useValidateDateRange";
import LedgerTable from "../LedgerTable";
import DateRangePicker from "../../DateRangePicker";
import BusinessEntitySelect from "../BusinessEntitySelect";
import StackedBarGraph from "./StackedBarGraph";
import SectionTitle from "../../SectionTitle";
import PageTitle from "../../PageTitle";
import { YYYY_MM_DD_FORMAT } from "../../../utils";
import UnverifiedDataWarningBanner from "../../UnverifiedDataWarning/UnverifiedDataWarningBanner";
import ValidUpUntil from "../ValidUpUntil";

const HEADERS = ["Category", "Percentage of Revenue", "Total Revenue", ""];

function Revenue() {
    const dateRange = useSelector(selectDateRange);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(newDateRange));
    };

    const { data: revenueData } = useGetRevenueByCategoryQuery();
    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        includeIncomplete: false,
    });

    const dateRangeUnvalidated = useValidateDateRange(
        validUpUntilData,
        dateRange
    );

    if (!revenueData || !validUpUntilData || !businessEntities) return null;

    const maxDate = dayjs().format(YYYY_MM_DD_FORMAT);

    const multipleBusinessEntities = businessEntities.length > 1;

    return (
        <div className="flex flex-col px-[60px] pb-[60px] pt-[30px] gap-y-[30px]">
            {multipleBusinessEntities ? (
                <div className="flex flex-row gap-x-[30px]">
                    <ValidUpUntil />
                    <BusinessEntitySelect inline />
                </div>
            ) : (
                <ValidUpUntil />
            )}
            <div className="flex flex-col gap-y-[30px]">
                <PageTitle>Revenue By Category</PageTitle>
                <div className="flex flex-col gap-y-[20px]">
                    <SectionTitle>By Month</SectionTitle>
                    <StackedBarGraph />
                </div>
                <div className="flex flex-col gap-y-[20px]">
                    <SectionTitle>For Date Range</SectionTitle>
                    <DateRangePicker
                        dateRange={dateRange}
                        onChange={setDateRange}
                        maxDate={maxDate}
                        validUpUntil={validUpUntilData.valid_up_until}
                    />
                    {dateRangeUnvalidated && (
                        <UnverifiedDataWarningBanner
                            validUpUntil={validUpUntilData.valid_up_until}
                        />
                    )}
                    <LedgerTable data={revenueData} headers={HEADERS} />
                </div>
            </div>
        </div>
    );
}

export default Revenue;
