import firebase from "firebase/compat/app";
import * as Sentry from "@sentry/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import signOut from "./authentication/thunks/signOut";

const prepareHeaders = (idToken) => (headers) => {
    headers.set("authorization", `Bearer ${idToken}`);
    return headers;
};

const createBaseQuery = (isAdmin) => {
    const baseUrl = isAdmin
        ? `${process.env.REACT_APP_API_URL}/admin`
        : `${process.env.REACT_APP_API_URL}/api`;

    return async (args, api, extraOptions) => {
        // Try to get the token
        let idToken;
        try {
            idToken = await firebase.auth().currentUser.getIdToken();
        } catch (error) {
            Sentry.captureException(error);
            api.dispatch(signOut());
        }

        // Make the request with the token
        const fetchQuery = fetchBaseQuery({
            baseUrl,
            prepareHeaders: prepareHeaders(idToken),
        });
        return fetchQuery(args, api, extraOptions);
    };
};

export default createBaseQuery;
