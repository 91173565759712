/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../utils";
import UnverifiedDataWarningDropdown from "../../UnverifiedDataWarning/UnverifiedDataWarningDropdown";

function NetProfitGraphTooltip({ active, payload, validUpUntil }) {
    if (!active || payload.length === 0) {
        return null;
    }
    const dataPoint = payload[0].payload;
    const {
        month,
        revenue_cents,
        expense_cents,
        net_profit_cents,
        unverified_revenue_cents,
        unverified_expense_cents,
        unverified_net_profit_cents,
    } = dataPoint;

    const unverifiedDataPoint =
        revenue_cents === undefined &&
        expense_cents === undefined &&
        net_profit_cents === undefined &&
        unverified_revenue_cents !== undefined &&
        unverified_expense_cents !== undefined &&
        unverified_net_profit_cents !== undefined;

    return (
        <div className="flex flex-col w-min">
            <div
                className={`w-fit shadow-md bg-white  flex flex-col p-[12px] gap-y-[10px] ${
                    unverifiedDataPoint ? "rounded-t-[10px]" : "rounded-[10px]"
                }`}
            >
                <div className="text-[12px] font-normal text-flychainGray">
                    {month}
                </div>
                <div className="flex flex-col gap-y-[4px]">
                    <div className="flex flex-row mb-[10px] gap-x-[25px]">
                        <div>
                            <div className="text-[11px] font-[600] text-flychainPurple-light">
                                Revenue
                            </div>
                            <div className="text-[18px] font-[600]">
                                {getCurrencyString(
                                    unverifiedDataPoint
                                        ? unverified_revenue_cents
                                        : revenue_cents
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="text-[11px] font-[600] text-flychainGray">
                                Expenses
                            </div>
                            <div className="text-[18px] font-[600]">
                                {getCurrencyString(
                                    (unverifiedDataPoint
                                        ? unverified_expense_cents
                                        : expense_cents) * -1 // match the convention of showing expenses as a positive number
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="text-[11px] font-[600] text-flychainYellow">
                            Net Profit
                        </div>
                        <div className="text-[18px] font-[600]">
                            {getCurrencyString(
                                unverifiedDataPoint
                                    ? unverified_net_profit_cents
                                    : net_profit_cents
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {unverifiedDataPoint && (
                <UnverifiedDataWarningDropdown validUpUntil={validUpUntil} />
            )}
        </div>
    );
}

NetProfitGraphTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(
        PropTypes.shape({
            payload: PropTypes.shape({
                month: PropTypes.string.isRequired,
                revenue_cents: PropTypes.number,
                expense_cents: PropTypes.number,
                net_profit_cents: PropTypes.number,
                unverified_revenue_cents: PropTypes.number,
                unverified_expense_cents: PropTypes.number,
                unverified_net_profit_cents: PropTypes.number,
            }).isRequired,
        }).isRequired
    ),
    validUpUntil: PropTypes.string.isRequired,
};

NetProfitGraphTooltip.defaultProps = {
    active: false,
    payload: [],
};

export default NetProfitGraphTooltip;
