import React from "react";
import MainBenefitsCard from "../../MainBenefitsCard";
import CashbackSvg from "./Cashback.svg";

function Cashback() {
    return (
        <MainBenefitsCard
            imageSrc={CashbackSvg}
            title={
                <div>
                    1.5% Cashback<sup>3</sup> On All Spend
                </div>
            }
            body="Get an unlimited 1.5% cashback on all spend. Issue debit and credit cards (virtual or physical) with spend controls."
        />
    );
}

export default Cashback;
