import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ChevronsUpDownSvg from "../../assets/ChevronsUpDown";

function SelectDisplay({
    label,
    value,
    options,
    onClick,
    inline,
    dropdownOpen,
}) {
    const optionLabel = useMemo(() => {
        const option = options.find((opt) => opt.value === value);
        return option ? option.label : label;
    }, [value, options]);

    return (
        <div
            className={`flex flex-row cursor-pointer shadow-md bg-[#FFFFFF] min-w-[150px] py-[10px] px-[10px] ${
                dropdownOpen ? "rounded-t-[12px]" : "rounded-[12px]"
            }`}
            onClick={onClick}
        >
            <div className="flex flex-row gap-x-[15px] items-center">
                <div
                    className={`text-[16px] font-[400] ${
                        inline ? "whitespace-nowrap w-fit" : ""
                    }`}
                >
                    {optionLabel}
                </div>
                <ChevronsUpDownSvg fill="#000000" width={7} height={18} />
            </div>
        </div>
    );
}

SelectDisplay.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    onClick: PropTypes.func.isRequired,
    inline: PropTypes.bool,
    dropdownOpen: PropTypes.bool,
};

SelectDisplay.defaultProps = {
    inline: false,
    dropdownOpen: false,
};

export default SelectDisplay;
