import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../utils";
import UnverifiedDataWarningDropdown from "../../UnverifiedDataWarning/UnverifiedDataWarningDropdown";

function StackedBarGraphTooltip({ active, payload, validUpUntil }) {
    if (!active || payload.length === 0) {
        return null;
    }

    const { name: ledgerId, value, color, payload: dataPoint } = payload[0];
    const cleanLedgerId = ledgerId.split(" ")[0];
    const { month } = dataPoint;
    const proportion = dataPoint[`${cleanLedgerId} Proportion`];
    const unverifiedProportion =
        dataPoint[`${cleanLedgerId} Proportion Unverified`];
    const ledgerName = dataPoint[`${cleanLedgerId} Name`];

    if (
        cleanLedgerId === "totalUnverified" ||
        (unverifiedProportion === undefined && proportion === undefined) // For special case when denominator is 0
    ) {
        return null;
    }

    const unverifiedDataPoint =
        unverifiedProportion !== undefined && proportion === undefined;

    return (
        <div className="flex flex-col w-min min-w-[200px]">
            <div
                className={`w-fit shadow-md bg-white  flex flex-col p-[12px] gap-y-[10px] ${
                    unverifiedDataPoint ? "rounded-t-[10px]" : "rounded-[10px]"
                }`}
            >
                <div className="text-[12px] font-normal text-flychainGray">
                    {month}
                </div>
                <div className="text-[14px] font-semibold" style={{ color }}>
                    {ledgerName}
                </div>
                <div className="flex flex-row justify-between text-[18px] font-semibold gap-x-[30px]">
                    <span>{getCurrencyString(value)}</span>
                    <span>
                        {unverifiedDataPoint
                            ? `(${unverifiedProportion.toFixed(2)}%)`
                            : `(${proportion.toFixed(2)}%)`}
                    </span>
                </div>
            </div>
            {unverifiedDataPoint && (
                <UnverifiedDataWarningDropdown validUpUntil={validUpUntil} />
            )}
        </div>
    );
}

StackedBarGraphTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(
        PropTypes.shape({
            payload: PropTypes.shape({
                month: PropTypes.string.isRequired,
            }).isRequired,
            name: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
            color: PropTypes.string.isRequired,
        }).isRequired
    ),
    validUpUntil: PropTypes.string.isRequired,
};

StackedBarGraphTooltip.defaultProps = {
    active: false,
    payload: [],
};

export default StackedBarGraphTooltip;
