import React from "react";
import PropTypes from "prop-types";

function CombineSvg({ fill, height, width }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M10 18H5a3 3 0 0 1-3-3v-1" />
            <path d="M14 2a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2" />
            <path d="M20 2a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2" />
            <path d="m7 21 3-3-3-3" />
            <rect x="14" y="14" width="8" height="8" rx="2" />
            <rect x="2" y="2" width="8" height="8" rx="2" />
        </svg>
    );
}

CombineSvg.propTypes = {
    fill: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
};

CombineSvg.defaultProps = {
    height: undefined,
    width: undefined,
};

export default CombineSvg;
