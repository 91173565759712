import React from "react";
import PropTypes from "prop-types";
import LegendDot from "../Legend/LegendDot";
import LegendLine from "../Legend/LegendLine";

function MonthlyGraphLegend({ ledgerName }) {
    return (
        <div className="flex flex-row justify-center items-center gap-x-[20px]">
            <div className="flex flex-row items-center gap-x-[5px]">
                <LegendDot fill="#8D58FF" />
                <div className="text-[11px] font-[600] text-flychainPurple-light">
                    {ledgerName || "Value"}
                </div>
            </div>
            <div className="flex flex-row items-center gap-x-[5px]">
                <LegendLine fill="#757575" dashed />
                <div className="text-[11px] font-[600] text-flychainGray">
                    Unreviewed Data
                </div>
            </div>
        </div>
    );
}

MonthlyGraphLegend.propTypes = {
    ledgerName: PropTypes.string.isRequired,
};

export default MonthlyGraphLegend;
