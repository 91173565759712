import React from "react";
import SelectedProvider from "../FinancialReports/SelectedProvider";
import SharedAdvancedPaymentOnClaims from "../../Capital/AdvancedPaymentOnClaims";

function AdminAdvancedPaymentOnClaims() {
    return (
        <div>
            <SelectedProvider />
            <SharedAdvancedPaymentOnClaims />
        </div>
    );
}

export default AdminAdvancedPaymentOnClaims;
