import React from "react";
import PropTypes from "prop-types";

function PeriodButton({ period, isSelected, onClick }) {
    const handleClick = () => {
        onClick(period);
    };
    return (
        <div
            className={`hover:opacity-[60%] font-medium text-center bg-white shadow-md cursor-pointer text-[14px] p-[10px] rounded-[12px] w-[80px] ${
                isSelected && "text-flychainPurple-light"
            }`}
            onClick={handleClick}
        >
            {period}
        </div>
    );
}

PeriodButton.propTypes = {
    period: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default PeriodButton;
