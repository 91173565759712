import React, { useState } from "react";
import TaxesHeaderSection from "./TaxesHeaderSection";
import TaxBenefits from "./TaxBenefits";
import GettingStarted from "./GettingStarted";
import TaxIntroCallBanner from "./TaxIntroCallBanner";
import CalendlyModal from "../CalendlyModal";
import { CALENDLY_URLS } from "../CalendlyModal/constants";

function Taxes() {
    const [showTaxCallModal, setShowTaxCallModal] = useState(false);

    const onClick = () => {
        setShowTaxCallModal(true);
    };

    const onClose = () => {
        setShowTaxCallModal(false);
    };

    return (
        <div className="flex flex-col">
            <TaxesHeaderSection onClick={onClick} />
            <TaxBenefits />
            <GettingStarted onClick={onClick} />
            <TaxIntroCallBanner onClick={onClick} />
            {showTaxCallModal && (
                <CalendlyModal
                    onClose={onClose}
                    calendlyUrl={CALENDLY_URLS.TAX_CALL}
                />
            )}
        </div>
    );
}

export default Taxes;
