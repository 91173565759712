import React from "react";
import { useForm } from "react-hook-form";
import FormInputField from "../../../InputField/FormInputField";
import Button from "../../../Button";
import useGetMercurySignupLinkMutation from "../../../../hooks/queriesAndMutations/useGetMercurySignupLinkMutation";

function Signup() {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: "onChange",
    });

    const [getMercurySignupLink] = useGetMercurySignupLinkMutation();

    const handleFormSubmit = async (data) => {
        try {
            const response = await getMercurySignupLink(data.email);
            window.open(response.data.signup_link, "_blank");
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="flex flex-row items-center bg-white rounded-[48px] p-[4px]">
            <div className="flex-1 mr-[10px]">
                <FormInputField
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    validation={{
                        required: "Email is required",
                        pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email address",
                        },
                    }}
                    register={register}
                    error={errors.email}
                    showError={false}
                />
            </div>
            <Button
                onClick={handleSubmit(handleFormSubmit)}
                disabled={!isValid}
                className="rounded-full w-fit px-[18px] py-[10px]"
            >
                Sign Up
            </Button>
        </div>
    );
}

export default Signup;
