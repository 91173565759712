/* eslint-disable camelcase */
import React, { useState } from "react";
import Button from "../Button";
import getPostBody from "./utils";
import PlaidAccountsRadioGroup from "../Inputs/PlaidAccountsRadioGroup";
import SELECT_BANK_ACCOUNT_SUBHEADING from "../../themes/copy/SELECT_BANK_ACCOUNT_SUBHEADING";
import {
    useAddProviderBankAccountMutation,
    useGetPlaidItemsWithOnlyCheckingAccountsQuery,
} from "../../redux/slices/api";
import EMAIL from "../../themes/copy/email";
import PageTitle from "../PageTitle";
import styles from "./styles.module.css";

function SelectBankAccount() {
    const { data: plaidItemsWithOnlyCheckingAccounts } =
        useGetPlaidItemsWithOnlyCheckingAccountsQuery();

    const [currentExternalPlaidAccountId, setCurrentExternalPlaidAccountId] =
        useState("");

    const handleChange = (event) => {
        setCurrentExternalPlaidAccountId(event.target.value);
    };

    const [addProviderBankAccount] = useAddProviderBankAccountMutation();

    const onSubmit = () => {
        const body = getPostBody(
            plaidItemsWithOnlyCheckingAccounts,
            currentExternalPlaidAccountId
        );
        addProviderBankAccount(body);
    };

    return (
        <div className={styles.container}>
            <div className={styles.pageTitleContainer}>
                <PageTitle>Select Primary Checking Account</PageTitle>
            </div>
            <div className={styles.subtitle}>
                {SELECT_BANK_ACCOUNT_SUBHEADING}
            </div>
            {plaidItemsWithOnlyCheckingAccounts &&
                (plaidItemsWithOnlyCheckingAccounts.length > 0 ? (
                    <PlaidAccountsRadioGroup
                        plaidItems={plaidItemsWithOnlyCheckingAccounts}
                        onChange={handleChange}
                        currentValue={currentExternalPlaidAccountId}
                    />
                ) : (
                    <div className={styles.noCheckingAccountsContainer}>
                        <div className={styles.subtitle}>
                            No checking accounts were detected. For assistance,
                            please contact{" "}
                            <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
                        </div>
                    </div>
                ))}
            <div className={styles.buttonContainer}>
                <Button
                    onClick={onSubmit}
                    disabled={currentExternalPlaidAccountId.length === 0}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
}

export default SelectBankAccount;
