import React from "react";
import PageTitle from "../../PageTitle";
import MercuryDashboardSvg from "../../../assets/MercuryDashboard.svg";
import Logos from "./Logos";
import SignupAndScheduleDemo from "../SignupAndScheduleDemo";

function HeaderSection() {
    return (
        <div className="flex flex-row gap-x-[50px] p-[60px] bg-gradient-to-tr from-[#e6f0f4] from-28% to-flychainGreen-light to-90%">
            <div className="flex-[5] flex flex-col">
                <div className="mb-[40px]">
                    <Logos />
                </div>
                <div className="mb-[20px]">
                    <PageTitle>
                        Bringing Best-In-Class Banking To Healthcare
                    </PageTitle>
                </div>
                <div className="mb-[40px] text-[12px] font-normal">
                    Flychain and Mercury
                    <sup className="font-normal text-[8px]">1</sup> have
                    partnered up to bring a delightful banking experience to the
                    healthcare space. Mercury enables over 200K businesses of
                    all sizes to operate at their highest level. Join a growing
                    number of healthcare providers who trust Mercury to scale
                    their practice.
                </div>
                <SignupAndScheduleDemo />
            </div>
            <div className="flex-[4] flex flex-col justify-center">
                <img src={MercuryDashboardSvg} alt="MercuryDashboard" />
            </div>
        </div>
    );
}

export default HeaderSection;
