import React from "react";
import BillPayHeader from "./BillPayHeader";
import BillPayBenefits from "./BillPayBenefits";
import Onboarding from "../Onboarding";
import Disclaimers from "../Disclaimers";

function BillPay() {
    return (
        <div>
            <BillPayHeader />
            <BillPayBenefits />
            <Onboarding />
            <Disclaimers />
        </div>
    );
}

export default BillPay;
