import React from "react";
import MainBenefitsCard from "../../MainBenefitsCard";
import FdicInsuranceSvg from "./FdicInsurance.svg";

function FdicInsurance() {
    return (
        <MainBenefitsCard
            imageSrc={FdicInsuranceSvg}
            title={
                <div>
                    $5M FDIC<sup>2</sup> Insurance
                </div>
            }
            body="Get up to $5M in FDIC insurance through Mercury's partner banks and their use of sweep networks."
        />
    );
}

export default FdicInsurance;
