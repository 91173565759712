import React from "react";
import SelectedProvider from "./FinancialReports/SelectedProvider";
import SharedSavings from "../Savings";

function AdminSavings() {
    return (
        <div>
            <SelectedProvider />
            <SharedSavings />
        </div>
    );
}

export default AdminSavings;
