/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import InstitutionHeader from "../InstitutionHeader";
import ErrorDisplay from "./ErrorDisplay";
import AccountsConnected from "../AccountsConnected";
import AdminActions from "./AdminActions";

const getPlaidItemStatus = (plaidItem) => {
    const { plaid_error, accounts } = plaidItem;
    // Plaid item is fine so there's nothing to do.
    if (!plaid_error || !plaid_error.error_code)
        return <AccountsConnected numberOfAccounts={accounts.length} />;

    return (
        <div className="text-flychainError text-[14px] font-[400]">
            Broken Connection
        </div>
    );
};

function AdminPlaidItemHeader({ plaidItem }) {
    const { is_admin_linked, plaid_error } = plaidItem;

    return (
        <div className="flex flex-row justify-between items-end w-full gap-x-[10px]">
            <div className="mr-2 w-fit flex-2">
                <InstitutionHeader
                    plaidItem={plaidItem}
                    plaidItemStatus={getPlaidItemStatus(plaidItem)}
                />
            </div>
            {plaid_error && plaid_error.raw_plaid_error ? (
                <ErrorDisplay plaidItem={plaidItem} plaidError={plaid_error} />
            ) : (
                <div className="flex-1" /> // Render a placeholder div to keep the layout consistent
            )}
            <div className="flex flex-col gap-[2px] w-fit flex-2">
                <div className="font-medium text-[14px] w-fit">
                    Admin Linked:{" "}
                    {is_admin_linked ? (
                        <span className="text-green-600">TRUE</span>
                    ) : (
                        <span className="text-red-600">FALSE</span>
                    )}
                </div>
                <AdminActions plaidItem={plaidItem} />
            </div>
        </div>
    );
}

AdminPlaidItemHeader.propTypes = {
    plaidItem: PropTypes.object.isRequired,
};

export default AdminPlaidItemHeader;
