import React from "react";
import PropTypes from "prop-types";
function FileCheckSvg({ fill, height, width }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" />
            <path d="M14 2v4a2 2 0 0 0 2 2h4" />
            <path d="m9 15 2 2 4-4" />
        </svg>
    );
}

FileCheckSvg.propTypes = {
    fill: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
};

FileCheckSvg.defaultProps = {
    height: undefined,
    width: undefined,
};

export default FileCheckSvg;
