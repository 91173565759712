import dayjs from "dayjs";
import { YYYY_MM_DD_FORMAT } from "../../../utils";

const getDateRangeForAllTimeToEndDate = (endDate) => {
    const parsedEndDate = dayjs(endDate);
    return {
        startDate: parsedEndDate
            .subtract(200, "years")
            .format(YYYY_MM_DD_FORMAT),
        endDate,
    };
};

export default getDateRangeForAllTimeToEndDate;
