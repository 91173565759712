import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
    ResponsiveContainer,
    LineChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Line,
} from "recharts";
import dayjs from "dayjs";
import { getCurrencyString } from "../../../utils";
import {
    tickStyle,
    tickFormatter,
    splitMetric,
    isAfterValidDate,
    VALID_DATE_FORMATS,
} from "../utils";
import SharedDefsContextProvider, {
    useSharedDefs,
} from "../SharedDefsContextProvider";
import CashBalanceGraphTooltip from "./CashBalanceGraphTooltip";
import CashBalanceGraphLegend from "./CashBalanceGraphLegend";
import useGetFinancialReportsValidUpUntilQuery from "../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";

function CashBalanceGraphContent({ data }) {
    const defs = useSharedDefs();
    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    const dataPoints = useMemo(() => {
        if (data.length <= 365) {
            return data;
        }
        return data.slice(-365);
    }, [data]);

    if (!validUpUntilData) return null;

    const validUpUntil = validUpUntilData.valid_up_until;

    const firstDaysOfMonth = dataPoints
        .map((entry) => entry.date)
        .filter((date) => dayjs(date, "MMM DD YYYY").date() === 1);

    const validPoints = dataPoints.map((point, index) => {
        const cashBalance = splitMetric(
            point.cash_balance_cents,
            point.date,
            VALID_DATE_FORMATS.MMM_D_YYYY,
            validUpUntil
        );

        // If the cash balance is verified and the next month is after the valid up until date, set the unverified cash balance to the verified cash balance
        // This is to ensure that the graph is not showing a gap in the line chart
        if (
            cashBalance.verified &&
            index < dataPoints.length - 1 && // Check if there is a next data point
            isAfterValidDate(
                dataPoints[index + 1].date,
                VALID_DATE_FORMATS.MMM_D_YYYY,
                validUpUntil
            ) // Check if the next datapoint is after the valid up until date
        ) {
            cashBalance.unverified = cashBalance.verified;
        }

        return {
            ...point,
            cash_balance_cents: cashBalance.verified,
            unverified_cash_balance_cents: cashBalance.unverified,
        };
    });

    return (
        <ResponsiveContainer width="100%" height={200}>
            <LineChart data={validPoints} stackOffset="sign">
                <CartesianGrid
                    stroke="#CCCCCC"
                    strokeWidth={1}
                    strokeDasharray="3 3"
                    horizontal
                    vertical={false}
                />
                <XAxis
                    dataKey="date"
                    tick={tickStyle}
                    ticks={firstDaysOfMonth}
                    tickFormatter={tickFormatter}
                />
                <YAxis
                    tickFormatter={getCurrencyString}
                    width={100}
                    tick={tickStyle}
                />
                <Tooltip
                    content={
                        <CashBalanceGraphTooltip validUpUntil={validUpUntil} />
                    }
                />
                <Legend content={<CashBalanceGraphLegend />} />
                {defs}
                <Line
                    type="monotone"
                    dataKey="cash_balance_cents"
                    stroke="#8D58FF"
                    strokeWidth={1}
                    dot={false}
                />
                <Line
                    type="monotone"
                    dataKey="unverified_cash_balance_cents"
                    stroke="#8D58FF"
                    strokeWidth={1.5}
                    strokeDasharray="3 3"
                    dot={false}
                />
            </LineChart>
        </ResponsiveContainer>
    );
}

const dataProps = PropTypes.arrayOf(
    PropTypes.shape({
        date: PropTypes.string.isRequired,
        cash_balance_cents: PropTypes.number.isRequired,
    }).isRequired
);

CashBalanceGraphContent.propTypes = {
    data: dataProps.isRequired,
};

function CashBalanceGraph({ data }) {
    return (
        <SharedDefsContextProvider>
            <CashBalanceGraphContent data={data} />
        </SharedDefsContextProvider>
    );
}

CashBalanceGraph.propTypes = {
    data: dataProps.isRequired,
};

export default CashBalanceGraph;
