import React from "react";
import PageTitle from "../PageTitle";

function QBORedirect() {
    return (
        <div className="flex flex-col justify-center items-center p-16 h-full bg-flychainPurple-white">
            <div className="mb-5">
                <PageTitle>Thanks for connecting your QuickBooks</PageTitle>
            </div>
            <div className="text-base font-light text-center">
                We&apos;re currently working on migrating your QuickBooks data
                into Flychain. This should take a few business days and
                we&apos;ll email you when this is complete. In the meantime,
                feel free to explore the rest of the Flychain platform.
            </div>
        </div>
    );
}

export default QBORedirect;
