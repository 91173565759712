import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PageTitle from "../../PageTitle";
import LeftCaretSvg from "../../../assets/LeftCaret.svg";
import { selectDateRange } from "../../../redux/slices/dateRange";
import getDateRangeForAllTimeToEndDate from "./utils";
import useGetFinancialReportsValidUpUntilQuery from "../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import useGetDataForLedgerLinesQuery from "../../../hooks/queriesAndMutations/useGetDataForLedgerLinesQuery";
import useGetReportType from "../../../hooks/useGetReportType";
import REPORT_TYPES from "../constants";
import LedgerLinesMonthlyGraph from "./LedgerLinesMonthlyGraph";
import Lines from "./Lines";
import ValidUpUntil from "../ValidUpUntil";

function LedgerLines() {
    const navigate = useNavigate();
    const { ledgerId } = useParams();
    const [searchParams] = useSearchParams();
    const isInflow = searchParams.get("isInflow") === "true";

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    const reportType = useGetReportType();

    const dateRange =
        reportType === REPORT_TYPES.BALANCE_SHEET
            ? getDateRangeForAllTimeToEndDate(validUpUntilData.valid_up_until)
            : useSelector(selectDateRange);

    const { data: ledgerLinesData } = useGetDataForLedgerLinesQuery(
        dateRange,
        ledgerId,
        isInflow
    );

    const reportTypes = [
        REPORT_TYPES.REVENUE_BY_CATEGORY,
        REPORT_TYPES.EXPENSES_BY_CATEGORY,
        REPORT_TYPES.INCOME_STATEMENT,
    ];

    if (!ledgerLinesData || !validUpUntilData) return null;

    return (
        <div className="flex flex-col gap-y-[25px]">
            <div className="flex flex-row items-center gap-x-[20px] pt-[60px] ml-[30px]">
                <img
                    className="w-[10px] cursor-pointer"
                    src={LeftCaretSvg}
                    alt="LeftCaret"
                    onClick={() => {
                        navigate(-1);
                    }}
                />
                <PageTitle>{ledgerLinesData.ledger_name}</PageTitle>
            </div>
            <div className="flex flex-col gap-y-[30px] px-[60px] pb-[60px]">
                <ValidUpUntil />
                {reportTypes.includes(reportType) && (
                    <LedgerLinesMonthlyGraph
                        ledgerName={ledgerLinesData.ledger_name}
                    />
                )}
                <Lines ledgerLinesData={ledgerLinesData} />
            </div>
        </div>
    );
}

export default LedgerLines;
