import React from "react";
import SharedTable from "../../../../Table";
import AssetClassLabel from "./AssetClassLabel";

const HEADERS = [
    "Asset",
    "Expected 12 Month Return *",
    "Liquidity",
    "Principal Risk **",
];

const rows = [
    [
        <AssetClassLabel assetClass="MANAGED_MONEY_MARKET" />,
        "4.93% - 5.43%",
        "3-5 Business Days",
        "Near Zero",
    ],
    [
        <AssetClassLabel assetClass="MANAGED_TREASURIES" />,
        "4.84% - 5.84%",
        "3-5 Business Days",
        "Very Low",
    ],
    [
        <AssetClassLabel assetClass="MANAGED_INCOME" />,
        "4.50% - 12.50%",
        "3-5 Business Days",
        "Low",
    ],
];

function AssetClassesTable() {
    return <SharedTable headers={HEADERS} rows={rows} />;
}

export default AssetClassesTable;
