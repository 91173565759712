import React, { useState } from "react";
import Banner from "../Banner";
import CalendlyModal from "../CalendlyModal";
import { CALENDLY_URLS } from "../CalendlyModal/constants";

function SavingsCallBanner() {
    const [showSavingsCallModal, setShowSavingsCallModal] = useState(false);

    const onShowModalClick = () => {
        setShowSavingsCallModal(true);
    };

    const onModalClose = () => {
        setShowSavingsCallModal(false);
    };

    return (
        <>
            <Banner
                title="Identify Opportunities to Save"
                body="We've helped hundreds of businesses realize significant savings. From reducing health
            insurance premiums to securing discounts for equipment,
            we'll analyze your business's specific expenses and develop
            a bespoke playbook to drive savings and increase your
            bottom line."
                buttonText="Schedule Savings Call"
                buttonOnClick={onShowModalClick}
            />
            {showSavingsCallModal && (
                <CalendlyModal
                    onClose={onModalClose}
                    calendlyUrl={CALENDLY_URLS.SAVINGS_CALL}
                />
            )}
        </>
    );
}

export default SavingsCallBanner;
