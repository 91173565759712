import React from "react";
import PropTypes from "prop-types";

function LegendDot({ fill }) {
    return (
        <svg width="12" height="12">
            <circle cx="6" cy="6" r="5" fill={fill} />
        </svg>
    );
}

LegendDot.propTypes = {
    fill: PropTypes.string.isRequired,
};

export default LegendDot;
