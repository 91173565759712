import React from "react";
import { PropTypes } from "prop-types";

const getBackgroundColor = (color, disabled) => {
    if (color === "secondary") {
        return disabled ? "bg-[#D3D3D3] opacity-[80%]" : "bg-[#D3D3D3]";
    }
    return disabled
        ? "bg-flychainPurple-light opacity-[60%]"
        : "bg-flychainPurple-light";
};

const getTextColor = (color, disabled) => {
    if (color === "secondary") {
        return disabled ? "text-[#757575]" : "text-[#000000]";
    }
    return "text-white";
};

function Button({ onClick, disabled, children, className, color }) {
    const handleClick = (event) => {
        if (!disabled) {
            onClick(event);
        }
    };

    const baseClassName =
        className ||
        "inline-block rounded-full px-[16px] py-[12px] w-fit h-fit text-center";

    return (
        <div
            onClick={handleClick}
            className={`${baseClassName} ${getBackgroundColor(
                color,
                disabled
            )} ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
        >
            <div
                className={`text-sm font-medium whitespace-nowrap ${getTextColor(
                    color,
                    disabled
                )}`}
            >
                {children}
            </div>
        </div>
    );
}

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.string,
};

Button.defaultProps = {
    disabled: false,
    className: "",
    color: "primary",
};

export default Button;
