import React from "react";
import PropTypes from "prop-types";
import QuarterButton from "./QuarterButton";

function QuarterPicker({
    year,
    onChange,
    maxDate,
    validUpUntil = maxDate,
    setHoverUnvalidatedToggle,
}) {
    return (
        <div className="flex flex-col p-[5px] rounded-[12px] bg-[#fafafa]">
            <div className="flex flex-row my-[5px] gap-x-[10px]">
                <QuarterButton
                    year={year}
                    quarter={0}
                    onClick={onChange}
                    maxDate={maxDate}
                    validUpUntil={validUpUntil}
                    setHoverUnvalidatedToggle={setHoverUnvalidatedToggle}
                />
                <QuarterButton
                    year={year}
                    quarter={1}
                    onClick={onChange}
                    maxDate={maxDate}
                    validUpUntil={validUpUntil}
                    setHoverUnvalidatedToggle={setHoverUnvalidatedToggle}
                />
            </div>
            <div className="flex flex-row my-[5px] gap-x-[10px]">
                <QuarterButton
                    year={year}
                    quarter={2}
                    onClick={onChange}
                    maxDate={maxDate}
                    validUpUntil={validUpUntil}
                    setHoverUnvalidatedToggle={setHoverUnvalidatedToggle}
                />
                <QuarterButton
                    year={year}
                    quarter={3}
                    onClick={onChange}
                    maxDate={maxDate}
                    validUpUntil={validUpUntil}
                    setHoverUnvalidatedToggle={setHoverUnvalidatedToggle}
                />
            </div>
        </div>
    );
}

QuarterPicker.propTypes = {
    year: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.string.isRequired,
    validUpUntil: PropTypes.string,
    setHoverUnvalidatedToggle: PropTypes.func,
};

QuarterPicker.defaultProps = {
    validUpUntil: undefined,
    setHoverUnvalidatedToggle: undefined,
};

export default QuarterPicker;
