import { useGetUsersQuery as useAdminGetUsersQuery } from "../../../redux/slices/adminApi";
import { useGetUsersQuery as useProviderGetUsersQuery } from "../../../redux/slices/api";

const useGetUsersQuery = (params) => {
    // Get the providerId from the params
    const providerId = params ? params.providerId : null;

    // If providerId is provided via function params or url params, use the admin query
    if (providerId) {
        return useAdminGetUsersQuery(providerId);
    }

    // If providerId is not provided at all, use the provider query. Provider id will be passed in via the request's user context
    return useProviderGetUsersQuery();
};

export default useGetUsersQuery;
