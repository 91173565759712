import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectDateRange } from "../../../redux/slices/dateRange";
import { useGetCashFlowReportQuery as useAdminGetCashFlowReportQuery } from "../../../redux/slices/adminApi";
import { useGetCashFlowReportQuery as useProviderGetCashFlowReportQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetCashFlowReportQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId, businessEntityId } = useParams();

    const dateRange = useSelector(selectDateRange);

    if (isAdminDashboard) {
        return useAdminGetCashFlowReportQuery({
            providerId,
            businessEntityId,
            ...dateRange,
        });
    }

    return useProviderGetCashFlowReportQuery({
        businessEntityId,
        ...dateRange,
    });
};

export default useGetCashFlowReportQuery;
