import React from "react";
import PropTypes from "prop-types";
import MonthButton from "./MonthButton";

const MONTHS_PER_ROW = 4;

function MonthPicker({
    year,
    onChange,
    maxDate,
    validUpUntil = maxDate,
    setHoverUnvalidatedToggle,
}) {
    const monthButtons = Array.from({ length: 12 }, (_, i) => (
        <div className="mx-[5px]" key={i}>
            <MonthButton
                year={year}
                month={i}
                onClick={onChange}
                maxDate={maxDate}
                validUpUntil={validUpUntil}
                setHoverUnvalidatedToggle={setHoverUnvalidatedToggle}
            />
        </div>
    ));

    const rows = Array.from(
        { length: Math.ceil(monthButtons.length / MONTHS_PER_ROW) },
        (_, rowIndex) => (
            <div className="flex flex-row my-[5px]" key={rowIndex}>
                {monthButtons.slice(
                    rowIndex * MONTHS_PER_ROW,
                    (rowIndex + 1) * MONTHS_PER_ROW
                )}
            </div>
        )
    );

    return (
        <div className="flex flex-col p-[5px] rounded-[12px] bg-[#fafafa]">
            {rows}
        </div>
    );
}

MonthPicker.propTypes = {
    year: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.string.isRequired,
    validUpUntil: PropTypes.string,
    setHoverUnvalidatedToggle: PropTypes.func,
};

MonthPicker.defaultProps = {
    validUpUntil: undefined,
    setHoverUnvalidatedToggle: undefined,
};

export default MonthPicker;
