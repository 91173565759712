import { useParams } from "react-router-dom";
import { useGetSavingsQuery as useAdminGetSavingsQuery } from "../../../redux/slices/adminApi";
import { useGetSavingsQuery as useProviderGetSavingsQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetSavingsQuery = (params) => {
    const { businessEntityId } = params;

    const isAdminDashboard = useIsAdminDashboard();

    const { providerId } = useParams();

    if (isAdminDashboard) {
        return useAdminGetSavingsQuery({ providerId, businessEntityId });
    }

    return useProviderGetSavingsQuery({ businessEntityId });
};

export default useGetSavingsQuery;
