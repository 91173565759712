import React from "react";
import PropTypes from "prop-types";

function TriangleAlertSvg({ fill, width, height }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
            <path d="M12 9v4" />
            <path d="M12 17h.01" />
        </svg>
    );
}

TriangleAlertSvg.propTypes = {
    fill: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
};

TriangleAlertSvg.defaultProps = {
    width: undefined,
    height: undefined,
};

export default TriangleAlertSvg;
