import React from "react";
import dayjs from "dayjs";
import Alert from "../../Alert";
import useGetFinancialReportsValidUpUntilQuery from "../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import { MMMM_D_YYYY_FORMAT } from "../../../utils";

function ValidUpUntil() {
    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    if (!validUpUntilData) {
        return null;
    }

    const validUpUntilDate = dayjs(validUpUntilData.valid_up_until);
    const formattedDate = validUpUntilDate.format(MMMM_D_YYYY_FORMAT);
    const title = `Your books are valid up until ${formattedDate}`;
    const body = `Our bookkeepers have reviewed your books up until ${formattedDate}. Please exercise caution when looking at data after this date.`;

    return (
        <Alert severity="info" variant="outlined" title={title} body={body} />
    );
}

export default ValidUpUntil;
