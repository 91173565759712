import { useRef } from "react";
import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";
import raiseError from "../../redux/slices/error/thunks/raiseError";
import useLazyGetPlaidLinkTokenQuery from "../queriesAndMutations/useLazyGetPlaidLinkTokenQuery";
import useAddPlaidItemMutation from "../queriesAndMutations/useAddPlaidItemMutation";
import useUpdatePlaidItemMutation from "../queriesAndMutations/useUpdatePlaidItemMutation";

function usePlaidLink(plaidItemId) {
    const plaidHandlerRef = useRef(null);
    const dispatch = useDispatch();
    const [triggerGetPlaidLinkToken] = useLazyGetPlaidLinkTokenQuery();
    const [triggerAddPlaidItemMutation] = useAddPlaidItemMutation();
    const [triggerUpdatePlaidItemMutation] = useUpdatePlaidItemMutation();

    const onLinkSuccess = (publicToken) => {
        if (plaidItemId) {
            triggerUpdatePlaidItemMutation(plaidItemId);
        } else {
            triggerAddPlaidItemMutation(publicToken);
        }

        plaidHandlerRef.current.destroy();
    };

    const onExit = (error) => {
        if (error) {
            Sentry.captureException(error);
        }
        plaidHandlerRef.current.destroy();
    };

    const openPlaidLink = async () => {
        let linkToken;
        try {
            const result = await triggerGetPlaidLinkToken(plaidItemId).unwrap();
            linkToken = result.link_token;
        } catch (error) {
            // We don't need to raiseException here because middleware will handle exceptions
            return;
        }

        try {
            const config = {
                token: linkToken,
                onSuccess: onLinkSuccess,
                onExit,
            };
            // eslint-disable-next-line no-undef
            const handler = Plaid.create(config);
            plaidHandlerRef.current = handler;
            plaidHandlerRef.current.open();
        } catch (error) {
            dispatch(raiseError(error));
        }
    };

    return { openPlaidLink };
}

export default usePlaidLink;
