import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectDateRange } from "../../../redux/slices/dateRange";
import { useGetRevenueByCategoryQuery as useAdminGetRevenueByCategoryQuery } from "../../../redux/slices/adminApi";
import { useGetRevenueByCategoryQuery as useProviderGetRevenueByCategoryQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetRevenueByCategoryQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId, businessEntityId } = useParams();

    const dateRange = useSelector(selectDateRange);

    if (isAdminDashboard) {
        return useAdminGetRevenueByCategoryQuery({
            providerId,
            businessEntityId,
            ...dateRange,
        });
    }

    return useProviderGetRevenueByCategoryQuery({
        businessEntityId,
        ...dateRange,
    });
};

export default useGetRevenueByCategoryQuery;
