import React from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import {
    getButtonClassName,
    handleButtonMouseEnter,
    handleButtonMouseLeave,
} from "../utils";

function MonthButton({
    year,
    month,
    onClick,
    maxDate,
    validUpUntil = maxDate,
    setHoverUnvalidatedToggle,
}) {
    const parsedMaxDate = dayjs(maxDate);
    const parsedValidUpUntil = dayjs(validUpUntil);
    const parsedMonth = dayjs().year(year).month(month);

    const isYearExceeding = year > parsedMaxDate.year();
    const isMonthExceeding =
        year === parsedMaxDate.year() && month > parsedMaxDate.month();

    const disabled = isYearExceeding || isMonthExceeding;

    // Checks if the valid up until date is the last day of the target month
    const validUntilLastDayOfTargetMonth =
        parsedValidUpUntil.date() === parsedValidUpUntil.daysInMonth() &&
        parsedMonth.isSame(parsedValidUpUntil, "month");

    // If the valid up until date is not the last day of the target month and the month is between the valid up until date and the max date (inclusive), then the month is unvalidated
    const unvalidated =
        parsedMonth.isBetween(
            parsedValidUpUntil,
            parsedMaxDate,
            "month",
            "[]"
        ) && // inclusive of the start date and the end date
        !validUntilLastDayOfTargetMonth;

    const handleClick = () => {
        if (!disabled) {
            onClick(month);
        }
    };

    return (
        <div
            className={`p-[10px] shadow-[0px_2px_8px_0px_#0000001f] rounded-xl w-[50px] text-[14px] font-medium text-center ${getButtonClassName(
                disabled,
                unvalidated
            )}`}
            onClick={handleClick}
            onMouseEnter={
                setHoverUnvalidatedToggle
                    ? () =>
                          handleButtonMouseEnter(
                              disabled,
                              unvalidated,
                              setHoverUnvalidatedToggle
                          )
                    : undefined
            }
            onMouseLeave={
                setHoverUnvalidatedToggle
                    ? () =>
                          handleButtonMouseLeave(
                              disabled,
                              unvalidated,
                              setHoverUnvalidatedToggle
                          )
                    : undefined
            }
        >
            {dayjs().month(month).format("MMM")}
        </div>
    );
}

MonthButton.propTypes = {
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    maxDate: PropTypes.string.isRequired,
    validUpUntil: PropTypes.string,
    setHoverUnvalidatedToggle: PropTypes.func,
};

MonthButton.defaultProps = {
    validUpUntil: undefined,
    setHoverUnvalidatedToggle: undefined,
};

export default MonthButton;
