import React from "react";
import PropTypes from "prop-types";
import Period from "./Period";
import PERIODS from "./constants";

function Periods({ period, onChange }) {
    return (
        <div className="flex flex-row px-[5px] py-[10px] rounded-[12px] bg-[#fafafa] gap-x-[10px]">
            <Period
                value={PERIODS.MONTH}
                isSelected={period === PERIODS.MONTH}
                onClick={onChange}
            />
            <Period
                value={PERIODS.DAY}
                isSelected={period === PERIODS.DAY}
                onClick={onChange}
            />
        </div>
    );
}

Periods.propTypes = {
    period: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default Periods;
