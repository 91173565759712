import React from "react";
import TreasuryHeaderSection from "./TreasuryHeaderSection";
import TreasuryDisclaimer from "./TreasuryDisclaimer";
import AssetClasses from "./AssetClasses";
import Security from "./Security";
import TrustedAdvisor from "./TrustedAdvisor";

function Treasury() {
    return (
        <div className="flex flex-col">
            <TreasuryHeaderSection />
            <AssetClasses />
            <Security />
            <TrustedAdvisor />
            <TreasuryDisclaimer />
        </div>
    );
}

export default Treasury;
