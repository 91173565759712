import React, { useEffect } from "react";
import firebase from "firebase/compat/app";
import { isMobile } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import Mobile from "../Mobile";
import ErrorBoundary from "../ErrorBoundary";
import raiseError from "../../redux/slices/error/thunks/raiseError";
import {
    setIsFirebaseAuthInitialized,
    setIsUserSignedIn,
} from "../../redux/slices/authentication";
import setUserInfo from "../../sentry";
import FirebaseAuthInitialized from "../FirebaseAuthInitialized";
import Loading from "../Loading";
import MobileModal from "../Modal/components/MobileModal";

function App() {
    if (isMobile) {
        return <Mobile />;
    }

    const dispatch = useDispatch();

    useEffect(() => {
        firebase.auth().onAuthStateChanged(
            (firebaseUser) => {
                if (firebaseUser) {
                    dispatch(setIsUserSignedIn(true));
                    setUserInfo(firebaseUser);
                } else {
                    dispatch(setIsUserSignedIn(false));
                    setUserInfo(null);
                }
                dispatch(setIsFirebaseAuthInitialized(true));
            },
            (error) => {
                dispatch(raiseError(error));
            }
        );
    }, []);

    const isFirebaseAuthInitialized = useSelector(
        (state) => state.authentication.isFirebaseAuthInitialized
    );

    return (
        <ErrorBoundary>
            <div className="flex flex-row w-[100vw] h-[100vh]">
                {isFirebaseAuthInitialized && <FirebaseAuthInitialized />}
                <MobileModal />
                <Loading />
            </div>
        </ErrorBoundary>
    );
}

export default App;
