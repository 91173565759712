import React from "react";
import PropTypes from "prop-types";
import Select from "../Select";

function SelectBusinessEntity({
    businessEntities,
    businessEntityId,
    onChange,
}) {
    return (
        <div className="fixed top-5 right-[60px] z-[1]">
            <Select
                label="Business Entity"
                value={businessEntityId}
                onChange={onChange}
                options={businessEntities.map((businessEntity) => ({
                    value: businessEntity.id,
                    label:
                        businessEntity.display_name ||
                        businessEntity.legal_name,
                }))}
                alignment="right"
            />
        </div>
    );
}

SelectBusinessEntity.propTypes = {
    businessEntities: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            display_name: PropTypes.string,
            legal_name: PropTypes.string.isRequired,
        })
    ).isRequired,
    businessEntityId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default SelectBusinessEntity;
