import React from "react";
import PropTypes from "prop-types";
import { getFriendlyString } from "../../../../../utils";

const ASSET_CLASS_COLORS = {
    TREASURE_CASH: "bg-[#F8CE39]",
    MANAGED_TREASURIES: "bg-[#110823]",
    MANAGED_MONEY_MARKET: "bg-[#CDE6F0]",
    MANAGED_INCOME: "bg-flychainGreen-light",
};

function AssetClassLabel({ assetClass }) {
    return (
        <div className="flex flex-row items-center">
            <span
                className={`w-[12px] h-[12px] rounded-[50%] mr-[10px] ${ASSET_CLASS_COLORS[assetClass]}`}
            />
            <span className="font-semibold">
                {getFriendlyString(assetClass)}
            </span>
        </div>
    );
}

AssetClassLabel.propTypes = {
    assetClass: PropTypes.string.isRequired,
};

export default AssetClassLabel;
