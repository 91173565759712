/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import SharedTable from "../../../../Table";
import { getCurrencyString, MMMM_D_YYYY_FORMAT } from "../../../../../utils";
import RightCaretSvg from "../../../../../assets/RightCaret.svg";

const HEADERS = [
    "Name",
    "Past 6 Months Total",
    "Recurring",
    "Most Recent",
    "Most Recent Date",
    "",
];

function Table({ data }) {
    const navigate = useNavigate();
    const rows = data.map((merchantDetail) => {
        const {
            expense_name,
            recurring_period,
            id,
            transactions,
            six_month_total_cents,
        } = merchantDetail;
        // We can assume that the expense has at least one transction.
        const { date, amount_cents } = transactions[0];
        const onClick = () => {
            navigate(`merchants/${id}`);
        };
        return [
            <span className="text-xs font-medium text-flychainGray">
                {expense_name}
            </span>,
            getCurrencyString(six_month_total_cents),
            recurring_period || "",
            getCurrencyString(amount_cents),
            dayjs(date).format(MMMM_D_YYYY_FORMAT),
            <div className="flex flex-row justify-center items-center w-full">
                <img
                    className="h-[15px] cursor-pointer"
                    src={RightCaretSvg}
                    alt="RightCaretSvg"
                    onClick={onClick}
                />
            </div>,
        ];
    });
    return <SharedTable headers={HEADERS} rows={rows} />;
}

Table.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default Table;
