import React from "react";
import SectionTitle from "../../../SectionTitle";
import AssetClassesTable from "./AssetClassesTable";

function AssetClasses() {
    return (
        <div className="flex flex-col p-[60px]">
            <SectionTitle className="mb-[10px]">
                Optimize Your Idle Cash
            </SectionTitle>
            <div className="mb-[20px] text-[14px]">
                Your cash should work for you instead of sitting in a checking
                or savings account. With treasury management, you can earn a
                return on your idle cash by investing it in following
                institutional assets.
            </div>
            <AssetClassesTable />
            <div className="text-right text-[10px] mt-[20px]">
                <p>
                    * Based on historical performance. Past performance is not a
                    guarantee of future returns.
                </p>
                <p>
                    ** Investment involves risk including potential loss of
                    principal.
                </p>
            </div>
        </div>
    );
}

export default AssetClasses;
