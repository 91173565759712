export const getButtonClassName = (disabled, unvalidated) => {
    if (disabled) {
        return "bg-[#f0f0f0] text-flychainGray cursor-not-allowed";
    }

    if (unvalidated) {
        return "bg-white text-flychainGray-light hover:opacity-[60%] cursor-pointer";
    }

    return "bg-white hover:text-flychainPurple-light cursor-pointer";
};

export const handleButtonMouseEnter = (
    disabled,
    unvalidated,
    setHoverUnvalidatedToggle
) => {
    if (!disabled && unvalidated) {
        setHoverUnvalidatedToggle(true);
    }
};

export const handleButtonMouseLeave = (
    disabled,
    unvalidated,
    setHoverUnvalidatedToggle
) => {
    if (!disabled && unvalidated) {
        setHoverUnvalidatedToggle(false);
    }
};
