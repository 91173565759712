import React from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import UnlinkSvg from "../../../../assets/Unlink";
import { useBreakPlaidItemMutation } from "../../../../redux/slices/adminApi";

function BreakConnectionMenuItem({ plaidItemId, closeMenu }) {
    const { providerId } = useParams();
    const [triggerBreakPlaidItemMutation] = useBreakPlaidItemMutation();

    const handleBreakConnection = () => {
        triggerBreakPlaidItemMutation({
            providerId,
            plaidItemId,
        });
        closeMenu();
    };

    return (
        <MenuItem
            key="break_connection"
            value="break_connection"
            className="flex flex-row items-center gap-[8px] hover:text-flychainPurple-light"
            onClick={handleBreakConnection}
        >
            <UnlinkSvg fill="currentColor" width={16} height={16} />
            Break Connection
        </MenuItem>
    );
}

BreakConnectionMenuItem.propTypes = {
    plaidItemId: PropTypes.string.isRequired,
    closeMenu: PropTypes.func.isRequired,
};

export default BreakConnectionMenuItem;
