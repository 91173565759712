import React from "react";
import LegendLine from "../Legend/LegendLine";

function StackedBarGraphLegend() {
    return (
        <div className="flex flex-row justify-center items-center gap-x-[20px]">
            <div className="flex flex-row items-center gap-x-[5px]">
                <LegendLine fill="#8D58FF" dashed />
                <div className="text-[11px] font-[600] text-flychainPurple-light">
                    Unreviewed Data
                </div>
            </div>
        </div>
    );
}

export default StackedBarGraphLegend;
