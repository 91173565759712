import React from "react";
import SavingsCard from "./SavingsCard";

function SavingsCards() {
    return (
        <div className="flex flex-col gap-y-[40px] px-[60px]">
            <div className="font-semibold text-[16px]">
                We help our providers realize multiple savings opportunities
                including:
            </div>
            <div className="flex flex-col gap-y-[30px]">
                <div className="flex flex-row justify-between">
                    <SavingsCard
                        title="Reduce Health Insurance Premiums"
                        body="Health insurance is more expensive than ever while also being more important than ever in recruiting and retaining employees. Our insurance experts do a side by side analysis to make sure you are offering the best health plans to your employees at the lowest possible cost."
                        tabText="Health Insurance"
                        tabColor="green"
                    />
                    <SavingsCard
                        title="Redeem Tax Credits"
                        body="Our team of CPAs and tax professionals offer complimentary tax planning meetings to help reduce your tax liability and redeem valuable tax credits such as R&D, ERC, and fixed assets."
                        tabText="Tax Credits"
                        tabColor="blue"
                    />
                    <SavingsCard
                        title="Reduce Liability Insurance Premiums"
                        body="Medical malpractice, professional, and general liability insurance can be major costs for your practice. Our insurance experts do a side by side analysis to make sure you have the best coverage at the lowest possible cost."
                        tabText="Liability Insurance"
                        tabColor="green"
                    />
                </div>
                <div className="flex flex-row justify-between">
                    <SavingsCard
                        title="Qualify for Business Grants"
                        body="Most states offer support for businesses in the form of grants and other financial incentives to encourage business growth. Our team of experts will help you identify which grants you qualify for and help you fill out the requisite applications."
                        tabText="Business Grants"
                        tabColor="purple"
                    />
                    <SavingsCard
                        title="Use Our Line of Credit"
                        body="Unlike other lenders, we don't charge origination fees, broker fees, or pre-payment penalties when you use our line of credit. Whenever you need capital, draw down on our line of credit and instantly save money on fees."
                        tabText="Financing"
                        tabColor="yellow"
                    />
                    <SavingsCard
                        title="Open a High-Yield Treasury Account"
                        body="Open a high-yield treasury account with our trusted brokerage partner. Put your operating capital and savings to work and earn an annual risk-free return of 5%+."
                        tabText="Treasury"
                        tabColor="blue-purple"
                    />
                </div>
            </div>
        </div>
    );
}

export default SavingsCards;
