/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import SharedTable from "../../../../Table";
import { getCurrencyString, MMMM_D_YYYY_FORMAT } from "../../../../../utils";

const HEADERS = ["Date", "Name", "Amount"];

function Table({ transactions }) {
    const rows = transactions.map((transaction) => {
        const { date, name, amount_cents } = transaction;
        return [
            dayjs(date).format(MMMM_D_YYYY_FORMAT),
            name,
            getCurrencyString(amount_cents),
        ];
    });
    return <SharedTable headers={HEADERS} rows={rows} />;
}

Table.propTypes = {
    transactions: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default Table;
