import React from "react";
import PropTypes from "prop-types";
import PeriodButton from "./PeriodButton";
import PERIODS from "./constants";

function Periods({ period, onChange }) {
    return (
        <div className="flex flex-row px-[5px] py-[10px] rounded-[12px] bg-[#fafafa] gap-x-[10px]">
            <PeriodButton
                period={PERIODS.MONTHLY}
                isSelected={period === PERIODS.MONTHLY}
                onClick={onChange}
            />
            <PeriodButton
                period={PERIODS.QUARTERLY}
                isSelected={period === PERIODS.QUARTERLY}
                onClick={onChange}
            />
            <PeriodButton
                period={PERIODS.ANNUAL}
                isSelected={period === PERIODS.ANNUAL}
                onClick={onChange}
            />
        </div>
    );
}

Periods.propTypes = {
    period: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default Periods;
