import React from "react";
import SectionTitle from "../../SectionTitle";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ValidUpUntil from "../ValidUpUntil";
import NetCashFlowGraph from "./NetCashFlowGraph";
import Report from "./Report";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";

function CashFlow() {
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        includeIncomplete: false,
    });

    if (!businessEntities) return null;

    const multipleBusinessEntities = businessEntities.length > 1;

    return (
        <div className="flex flex-col px-[60px] pb-[60px] pt-[30px] gap-y-[30px]">
            {multipleBusinessEntities ? (
                <div className="flex flex-row gap-x-[30px]">
                    <ValidUpUntil />
                    <BusinessEntitySelect inline />
                </div>
            ) : (
                <ValidUpUntil />
            )}
            <SectionTitle>Net Cash Flow by Month</SectionTitle>
            <NetCashFlowGraph />
            <div className="mt-[10px]">
                <Report />
            </div>
        </div>
    );
}

export default CashFlow;
