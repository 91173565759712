import React from "react";
import PropTypes from "prop-types";

function TrustedAdvisorStat({ title, subtitle }) {
    return (
        <div className="flex flex-col flex-1 items-center gap-y-[5px]">
            <div className="text-[34px] font-semibold">{title}</div>
            <div className="text-[16px] font-[500] text-flychainGray text-center">
                {subtitle}
            </div>
        </div>
    );
}

TrustedAdvisorStat.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
};

export default TrustedAdvisorStat;
