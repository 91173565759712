import React from "react";
import PropTypes from "prop-types";

function ChevronsUpDownSvg({ fill, width, height }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 12 18"
            fill="none"
            stroke={fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 12L6 17L11 12"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 6L6 1L11 6"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

ChevronsUpDownSvg.propTypes = {
    fill: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
};

ChevronsUpDownSvg.defaultProps = {
    width: undefined,
    height: undefined,
};

export default ChevronsUpDownSvg;
