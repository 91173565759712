import React from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import DayButton from "./DayButton";

const DAYS_PER_ROW = 7;

function DayPicker({
    year,
    month,
    onChange,
    maxDate,
    validUpUntil = maxDate,
    setHoverUnvalidatedToggle,
}) {
    const daysInMonth = dayjs()
        .year(year)
        .month(month)
        .startOf("month")
        .daysInMonth();

    const dayButtons = Array.from({ length: daysInMonth }, (_, i) => (
        <div className="mx-[3px]" key={i}>
            <DayButton
                year={year}
                month={month}
                day={i + 1}
                onClick={onChange}
                maxDate={maxDate}
                validUpUntil={validUpUntil}
                setHoverUnvalidatedToggle={setHoverUnvalidatedToggle}
            />
        </div>
    ));

    const rows = Array.from(
        { length: Math.ceil(dayButtons.length / DAYS_PER_ROW) },
        (_, rowIndex) => (
            <div className="flex flex-row my-[5px]" key={rowIndex}>
                {dayButtons.slice(
                    rowIndex * DAYS_PER_ROW,
                    (rowIndex + 1) * DAYS_PER_ROW
                )}
            </div>
        )
    );

    return (
        <div className="flex flex-col items-center gap-y-[5px]">
            <div className="text-[14px] font-medium">
                {dayjs().month(month).format("MMMM")}
            </div>
            <div className="flex flex-col p-[5px] rounded-[12px] bg-[#fafafa]">
                {rows}
            </div>
        </div>
    );
}

DayPicker.propTypes = {
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.string.isRequired,
    validUpUntil: PropTypes.string,
    setHoverUnvalidatedToggle: PropTypes.func.isRequired,
};

DayPicker.defaultProps = {
    validUpUntil: undefined,
};

export default DayPicker;
