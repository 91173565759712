import React from "react";
import PropTypes from "prop-types";
import PageTitle from "../../PageTitle";
import Button from "../../Button";
import WomanSmilingPng from "../../../assets/WomanSmiling.png";
import GettingStartedBullet from "./GettingStartedBullet";

const bullets = [
    {
        number: 1,
        description:
            "Schedule a free intro call with Rivet to get a quote and timeline for the filing.",
    },
    {
        number: 2,
        description:
            "Flychain works directly with Rivet to share your financials and any other required documentation.",
    },
    {
        number: 3,
        description:
            "Your taxes are filed seamlessly to minimize the work on your end while maximizing your tax savings.",
    },
];

function GettingStarted({ onClick }) {
    return (
        <div className="flex flex-row p-[60px] gap-x-[60px]">
            <div className="flex flex-row flex-[2] justify-center items-start">
                <img
                    className="w-[70%] aspect-square"
                    src={WomanSmilingPng}
                    alt="WomanSmiling"
                />
            </div>
            <div className="flex flex-col flex-[3] justify-center gap-y-[30px]">
                <PageTitle>How To Get Started</PageTitle>
                {bullets.map((bullet) => (
                    <GettingStartedBullet
                        key={bullet.number}
                        number={bullet.number}
                        description={bullet.description}
                    />
                ))}
                <div>
                    <Button color="secondary" onClick={onClick}>
                        Get Started
                    </Button>
                </div>
            </div>
        </div>
    );
}

GettingStarted.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default GettingStarted;
