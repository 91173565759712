import React from "react";
import EntitiesValuedTooltip from "../EntitiesValuedTooltip";
import ValuationChart from "./ValuationChart";
import useGetValuationQuery from "../../../hooks/queriesAndMutations/useGetValuationQuery";
import { getCurrencyString } from "../../../utils";

const displayContext = (noValuations, isPositiveValuation, valuationData) => {
    if (noValuations) {
        return (
            <div className="text-xs font-normal">
                We are still working on your books, so a valuation estimate is
                not yet available. For a full valuation, we recommend scheduling
                a free valuation with our M&A partner.
            </div>
        );
    }

    if (!isPositiveValuation) {
        return (
            <div className="text-xs font-normal">
                The total valuation across all of your business entities is
                negative or zero; therefore, we are unable to provide a
                Valuation Range at this time. For a full valuation, we recommend
                scheduling a free valuation with our M&A partner. To see how we
                calculated valuations for each of your business entities, please
                refer to the section below.
            </div>
        );
    }

    return (
        <EntitiesValuedTooltip
            valuationData={valuationData}
            className="text-xs font-normal underline text-flychainPurple-light"
        />
    );
};

function ValuationRange() {
    const { data: valuationData } = useGetValuationQuery();

    if (!valuationData) return null;

    const totalValuationMin = valuationData.valuations.reduce(
        (min, valuation) => min + valuation.valuation_cents[0],
        0
    );
    const totalValuationMax = valuationData.valuations.reduce(
        (max, valuation) => max + valuation.valuation_cents[1],
        0
    );

    const totalValuationMean = (totalValuationMin + totalValuationMax) / 2; // divide by 2 for the mean

    const noValuations = valuationData.valuations.length === 0;

    const isPositiveValuation = totalValuationMin > 0 && totalValuationMax > 0;

    return (
        <>
            <div className="rounded-xl shadow-lg border-[1px] p-0">
                <div
                    className={`flex flex-col w-full h-fit gap-y-[10px] ${
                        isPositiveValuation && "pb-[12px]"
                    }`}
                >
                    <div
                        className={`flex flex-col ${
                            isPositiveValuation
                                ? "px-[24px] pt-[24px] gap-y-[10px]"
                                : "p-[24px] gap-y-[50px]"
                        }`}
                    >
                        <div className="text-[15px] font-semibold">
                            Valuation Range
                        </div>
                        <div className={!isPositiveValuation && "blur-lg"}>
                            <div className="flex flex-row items-end gap-x-[6px] flex-wrap">
                                <span className="text-2xl font-bold">
                                    {getCurrencyString(totalValuationMin)}
                                </span>
                                <span className="text-lg font-bold">to</span>
                                <span className="text-2xl font-bold">
                                    {getCurrencyString(totalValuationMax)}
                                </span>
                            </div>
                        </div>
                        {displayContext(
                            noValuations,
                            isPositiveValuation,
                            valuationData
                        )}
                    </div>
                    {isPositiveValuation && (
                        <ValuationChart
                            data={[
                                {
                                    value: totalValuationMean,
                                    error: [
                                        totalValuationMean - totalValuationMin,
                                        totalValuationMax - totalValuationMean,
                                    ],
                                },
                            ]}
                        />
                    )}
                </div>
            </div>
            {isPositiveValuation && (
                <div className="font-normal text-[10px] text-flychainGray mt-[20px]">
                    * The valuation range provided above is intended for general
                    informational purposes only and should not be considered as
                    financial, investment, or legal advice. Flychain assumes no
                    responsibility for any decisions made based on this
                    valuation and disclaims all liability in connection with its
                    use.
                </div>
            )}
        </>
    );
}

export default ValuationRange;
