/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../utils";
import SavingsTable from "./SavingsTable";
import useGetSavingsQuery from "../../hooks/queriesAndMutations/useGetSavingsQuery";

function RealizedSavings({ businessEntityId }) {
    const { data: savingsData, isFetching } = useGetSavingsQuery({
        businessEntityId,
    });

    if (!savingsData || isFetching) {
        return null;
    }

    return (
        <div className="flex flex-col gap-y-[30px] px-[60px]">
            <div className="flex flex-row items-center gap-x-[20px]">
                <div className="font-semibold text-[17px]">
                    Total Amount Saved
                </div>
                <div className="font-[700] text-[30px]">
                    {getCurrencyString(savingsData.total_amount_saved_cents)}
                </div>
            </div>
            {savingsData.savings.length > 0 && (
                <SavingsTable savings={savingsData.savings} />
            )}
        </div>
    );
}

RealizedSavings.propTypes = {
    businessEntityId: PropTypes.string.isRequired,
};

export default RealizedSavings;
