/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";

function ErrorDisplay({ plaidItem, plaidError }) {
    const tooltipTitle = (
        <div className="flex flex-row w-fit p-1 text-[12px] gap-x-[10px]">
            <div className="flex flex-col flex-2 gap-y-[5px]">
                <div className="w-fit no-wrap">Internal Item ID:</div>
                <div className="w-fit no-wrap">Error Code:</div>
                <div className="w-fit no-wrap">Detected At:</div>
                <div className="w-fit no-wrap">Message:</div>
            </div>
            <div className="flex flex-col flex-1 gap-y-[5px]">
                <div>{plaidItem.internal_plaid_item_id}</div>
                <div>{plaidError.error_code}</div>
                <div>{plaidError.detected_at}</div>
                <div>{plaidError.raw_plaid_error.error_message}</div>
            </div>
        </div>
    );

    return (
        <Tooltip
            title={tooltipTitle}
            className="w-fit"
            componentsProps={{
                popper: {
                    sx: {
                        "& .MuiTooltip-tooltip": {
                            maxWidth: 500,
                        },
                    },
                },
            }}
        >
            <div className="flex flex-col flex-1 px-2 text-[12px] cursor-help">
                <div className="flex flex-row items-center gap-x-[10px]">
                    <div className="text-flychainGray">Internal Item ID:</div>{" "}
                    <div>{plaidItem.internal_plaid_item_id}</div>
                </div>
                <div className="flex flex-row items-center gap-x-[10px]">
                    <div className="text-flychainGray">Error Code:</div>{" "}
                    <div>{plaidError.error_code}</div>
                </div>
                <div className="flex flex-row items-start gap-x-[10px]">
                    <div className="text-flychainGray">Message:</div>
                    <div className="line-clamp-1">
                        {plaidError.raw_plaid_error.error_message}
                    </div>
                </div>
            </div>
        </Tooltip>
    );
}

ErrorDisplay.propTypes = {
    plaidItem: PropTypes.object.isRequired,
    plaidError: PropTypes.object.isRequired,
};

export default ErrorDisplay;
