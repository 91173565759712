import React from "react";
import Button from "../Button";
import PageTitle from "../PageTitle";
import FlychainLogoSvg from "../../assets/FlychainLogo.svg";
import styles from "./styles.module.css";

function ErrorFallback() {
    const onClick = () => {
        window.location.href = window.location.origin; // Redirects to the root of the current domain
    };

    return (
        <div className={styles.container}>
            <img
                className={styles.logo}
                src={FlychainLogoSvg}
                alt="FlychainLogo"
            />
            <div className={styles.pageTitleContainer}>
                <PageTitle>An unexpected error occured</PageTitle>
            </div>
            <div className={styles.subtitle}>
                Please refresh the page by clicking the button below
            </div>
            <Button onClick={onClick}>Refresh</Button>
        </div>
    );
}

export default ErrorFallback;
