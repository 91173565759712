import React from "react";
import PropTypes from "prop-types";
import SharedCard from "../../../Card";

function SecurityCard({ title, subtitle, icon, className }) {
    return (
        <SharedCard showTab={false} className={className}>
            <div className="flex flex-col gap-y-[15px]">
                <div className="flex flex-row items-center gap-x-[12px] justify-start">
                    {icon}
                    <div className="text-[16px] font-semibold">{title}</div>
                </div>
                <div className="text-[14px]">{subtitle}</div>
            </div>
        </SharedCard>
    );
}

SecurityCard.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    icon: PropTypes.element,
    className: PropTypes.string,
};

SecurityCard.defaultProps = {
    icon: undefined,
    className: "",
};

export default SecurityCard;
