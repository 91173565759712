import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MONTHS, STATES, ENTITY_TYPES, SPECIALTIES } from "./constants";
import REQUIRED_MESSAGE from "../../Inputs/constants";
import PageTitle from "../../PageTitle";
import SectionTitle from "../../SectionTitle";
import TextInput from "../../Inputs/TextInput";
import FormSelectInput from "../../Inputs/SelectInput/FormSelectInput";
import Button from "../../Button";
import LeftCaret from "../../../assets/LeftCaret.svg";
import { useCreateBusinessEntityMutation } from "../../../redux/slices/api";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import useGetProviderPath from "../../../hooks/useGetProviderPath";
import {
    getFriendlyString,
    blankStringToNull,
    notBlankString,
} from "../../../utils";

export const specialtyOptions = SPECIALTIES.map((specialty) => ({
    value: specialty,
    label: getFriendlyString(specialty),
}));

export const entityTypeOptions = ENTITY_TYPES.map((entityType) => ({
    value: entityType,
    label: entityType === "LLC" ? entityType : getFriendlyString(entityType),
}));

const defaultValues = {
    legalName: "",
    dba: null,
    displayName: null,
    ein: "",
    businessStartDateMonth: "",
    businessStartDateYear: "",
    businessEntityType: "",
    specialty: null,
    streetAddress: "",
    city: "",
    state: "",
    postalCode: "",
    contactFullName: "",
    contactEmail: "",
    contactPhone: "",
};

function BusinessEntityCreatorContent({ businessEntities }) {
    const navigate = useNavigate();
    const [createBusinessEntity] = useCreateBusinessEntityMutation();
    const providerPath = useGetProviderPath();

    const schema = yup
        .object({
            legalName: yup
                .string()
                .test(
                    "unique-legal-name",
                    "Legal name already in use",
                    (value) =>
                        !businessEntities.some(
                            (entity) => entity.legal_name === value
                        )
                )
                .required(REQUIRED_MESSAGE)
                .test(
                    "not-whitespace-only",
                    "Legal name cannot contain only whitespace",
                    notBlankString
                ),
            dba: yup.string().nullable(),
            displayName: yup.string().nullable(),
            ein: yup
                .string()
                .matches(/^\d{9}$/, "Must be 9 numbers (no hyphens)")
                .test(
                    "unique-ein",
                    "EIN already in use",
                    (value) =>
                        !businessEntities.some((entity) => entity.ein === value)
                ),
            businessStartDateMonth: yup
                .string()
                .required(REQUIRED_MESSAGE)
                .test(
                    "not-whitespace-only",
                    "Start month cannot contain only whitespace",
                    notBlankString
                ),
            businessStartDateYear: yup
                .string()
                .matches(/^\d{4}$/, "Must be YYYY"),
            businessEntityType: yup.string().required(REQUIRED_MESSAGE),
            specialty: yup.string().required(REQUIRED_MESSAGE),
            streetAddress: yup
                .string()
                .required(REQUIRED_MESSAGE)
                .test(
                    "not-whitespace-only",
                    "Street address cannot contain only whitespace",
                    notBlankString
                ),
            city: yup
                .string()
                .required(REQUIRED_MESSAGE)
                .test(
                    "not-whitespace-only",
                    "City cannot contain only whitespace",
                    notBlankString
                ),
            state: yup.string().required(REQUIRED_MESSAGE),
            postalCode: yup.string().matches(/^\d{5}$/, "Must be 5 numbers"),
            contactFullName: yup
                .string()
                .required(REQUIRED_MESSAGE)
                .test(
                    "not-whitespace-only",
                    "Contact name cannot contain only whitespace",
                    notBlankString
                ),
            contactEmail: yup.string().email("Invalid email address"),
            contactPhone: yup
                .string()
                .matches(
                    /^\d{10,11}$/,
                    "Must be 10 or 11 numbers (no parentheses or hypens)"
                ),
        })
        .required();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    const onSubmit = (formData) => {
        const body = {
            legal_name: formData.legalName,
            dba: blankStringToNull(formData.dba),
            display_name: blankStringToNull(formData.displayName),
            ein: formData.ein,
            business_start_date_month: formData.businessStartDateMonth,
            business_start_date_year: formData.businessStartDateYear,
            business_entity_type: formData.businessEntityType,
            specialty: formData.specialty,
            street_address: formData.streetAddress,
            city: formData.city,
            state: formData.state,
            postal_code: formData.postalCode,
            contact_full_name: formData.contactFullName,
            contact_email: formData.contactEmail,
            contact_phone: formData.contactPhone,
        };
        createBusinessEntity(body);
        navigate(`${providerPath}/settings?tab=businessEntities`);
    };

    const triggerSubmit = () => {
        handleSubmit(onSubmit)();
    };

    return (
        <div className="flex flex-col p-[60px] gap-y-[30px]">
            <div className="flex flex-row items-center gap-x-[15px]">
                <img
                    src={LeftCaret}
                    alt="Back"
                    className="w-[20px] h-[20px] cursor-pointer hover:opacity-50 brightness-0"
                    onClick={() =>
                        navigate(
                            `${providerPath}/settings?tab=businessEntities`
                        )
                    }
                />
                <PageTitle>Create Business Entity</PageTitle>
            </div>
            <div className="flex flex-col gap-y-[30px]">
                <div className="flex flex-col p-[15px] border-[0.2px] rounded-xl shadow-lg">
                    <SectionTitle>Business Entity Details</SectionTitle>
                    <div className="flex flex-col gap-y-[15px]">
                        <TextInput
                            name="legalName"
                            label="Business legal name *"
                            control={control}
                            error={errors.legalName}
                        />
                        <TextInput
                            name="dba"
                            label="Doing business as (DBA)"
                            control={control}
                            error={errors.dba}
                        />
                        <TextInput
                            name="displayName"
                            label="Display name"
                            control={control}
                            error={errors.displayName}
                        />
                        <TextInput
                            name="ein"
                            label="Business Tax ID/EIN *"
                            control={control}
                            error={errors.ein}
                            placeholder="XXXXXXXXX"
                        />
                        <FormSelectInput
                            name="businessStartDateMonth"
                            label="Business start date (month) *"
                            options={MONTHS}
                            control={control}
                            error={errors.businessStartDateMonth}
                        />
                        <TextInput
                            name="businessStartDateYear"
                            label="Business start date (year) *"
                            control={control}
                            error={errors.businessStartDateYear}
                        />
                        <FormSelectInput
                            name="businessEntityType"
                            label="Business entity type *"
                            options={entityTypeOptions}
                            control={control}
                            error={errors.businessEntityType}
                        />
                        <FormSelectInput
                            name="specialty"
                            label="Specialty *"
                            options={specialtyOptions}
                            control={control}
                            error={errors.specialty}
                        />
                    </div>
                </div>
                <div className="flex flex-col p-[15px] border-[0.2px] rounded-xl shadow-lg">
                    <SectionTitle>Business Entity Address</SectionTitle>
                    <div className="flex flex-col gap-y-[15px]">
                        <TextInput
                            name="streetAddress"
                            label="Street address *"
                            control={control}
                            error={errors.streetAddress}
                        />
                        <TextInput
                            name="city"
                            label="City *"
                            control={control}
                            error={errors.city}
                        />
                        <FormSelectInput
                            name="state"
                            label="State *"
                            options={STATES}
                            control={control}
                            error={errors.state}
                        />
                        <TextInput
                            name="postalCode"
                            label="Postal code *"
                            control={control}
                            error={errors.postalCode}
                        />
                    </div>
                </div>
                <div className="flex flex-col p-[15px] border-[0.2px] rounded-xl shadow-lg">
                    <SectionTitle>Contact Information</SectionTitle>
                    <div className="flex flex-col gap-y-[15px]">
                        <TextInput
                            name="contactFullName"
                            label="Contact full name *"
                            control={control}
                            error={errors.contactFullName}
                        />
                        <TextInput
                            name="contactEmail"
                            label="Contact email *"
                            control={control}
                            error={errors.contactEmail}
                        />
                        <TextInput
                            name="contactPhone"
                            label="Contact phone number *"
                            control={control}
                            error={errors.contactPhone}
                        />
                    </div>
                </div>
                <Button onClick={triggerSubmit}>Submit</Button>
                {Object.keys(errors).length > 0 && (
                    <div className="flex flex-row justify-center">
                        <div className="text-sm font-light text-flychainError">
                            There was an error in the form. Errors are shown
                            under each field.
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

BusinessEntityCreatorContent.propTypes = {
    businessEntities: PropTypes.array.isRequired,
};

// Wrapper component for the BusinessEntityCreatorContent component
// This component is responsible for fetching the business entities
// and passing them down to the BusinessEntityCreatorContent component.
function BusinessEntityCreator() {
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        includeIncomplete: true,
    });

    if (!businessEntities) return null;

    return <BusinessEntityCreatorContent businessEntities={businessEntities} />;
}

export default BusinessEntityCreator;
