import React from "react";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";

function EntitiesValuedTooltip({ valuationData, className }) {
    const unvaluedEntities = [
        ...valuationData.insufficient_data,
        ...valuationData.incomplete,
    ];

    const tooltipTitle =
        unvaluedEntities.length > 0 ? (
            <p>
                We&apos;re still working on the books for the following business
                entities, so they are not included in the valuation range:
                <br />
                <br />
                {unvaluedEntities.map((entity) => (
                    <p>- {entity.legal_name}</p>
                ))}
            </p>
        ) : (
            "All of your business entities were considered in the valuation range."
        );

    return (
        <Tooltip title={tooltipTitle}>
            <div className={`cursor-help w-fit ${className}`}>
                Business Entities Valued: {valuationData.valuations.length}/
                {unvaluedEntities.length + valuationData.valuations.length}
            </div>
        </Tooltip>
    );
}

EntitiesValuedTooltip.propTypes = {
    valuationData: PropTypes.object.isRequired,
    className: PropTypes.string,
};

EntitiesValuedTooltip.defaultProps = {
    className: "",
};

export default EntitiesValuedTooltip;
