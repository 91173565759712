import React from "react";
import PropTypes from "prop-types";
import Button from "../Button";

function Banner({
    title,
    body,
    buttonText,
    buttonOnClick,
    backgroundColor,
    titleColor,
    bodyColor,
}) {
    return (
        <div
            className={`flex flex-col items-center p-[60px] ${backgroundColor}`}
        >
            <div
                className={`text-3xl font-semibold text-center mb-[30px] ${titleColor}`}
            >
                {title}
            </div>
            <div
                className={`text-sm font-normal text-center mb-[30px] ${bodyColor} px-[100px]`}
            >
                {body}
            </div>
            <div>
                <Button onClick={buttonOnClick}>{buttonText}</Button>
            </div>
        </div>
    );
}

Banner.propTypes = {
    title: PropTypes.string.isRequired,
    titleColor: PropTypes.string,
    body: PropTypes.string.isRequired,
    bodyColor: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    buttonOnClick: PropTypes.func.isRequired,
    backgroundColor: PropTypes.string,
};

Banner.defaultProps = {
    backgroundColor: "bg-flychainPurple-dark",
    titleColor: "text-white",
    bodyColor: "text-white",
};

export default Banner;
