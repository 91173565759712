export const buildDestinations = {
    FLYCHAIN: "FLYCHAIN",
    ADMIN: "ADMIN",
    STAGING: "STAGING",
};

if (
    !Object.values(buildDestinations).includes(
        process.env.REACT_APP_BUILD_DESTINATION
    )
) {
    throw new Error("Invalid REACT_APP_BUILD_DESTINATION");
}

export const BUILD_DESTINATION = process.env.REACT_APP_BUILD_DESTINATION;
