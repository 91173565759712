import React from "react";
import PropTypes from "prop-types";

function SelectDropdown({ value, options, onClick, alignment }) {
    return (
        <div
            className={`flex flex-col w-fit min-w-full absolute z-[1] top-[69px] shadow-md rounded-b-[5px] bg-white p-[8px] ${
                alignment === "left" ? "left-0" : "right-0"
            }`}
        >
            {options.map((option) => (
                <div
                    key={option.value}
                    onClick={() => onClick(option)}
                    className={`cursor-pointer p-2.5 whitespace-nowrap text-sm font-normal hover:bg-[#f0f0f0] ${
                        value === option.value
                            ? "bg-flychainPurple-light bg-opacity-[0.15]"
                            : ""
                    }`}
                >
                    {option.label}
                </div>
            ))}
        </div>
    );
}

SelectDropdown.propTypes = {
    value: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    onClick: PropTypes.func.isRequired,
    alignment: PropTypes.oneOf(["left", "right"]).isRequired,
};

export default SelectDropdown;
