/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../utils";
import UnverifiedDataWarningDropdown from "../../UnverifiedDataWarning/UnverifiedDataWarningDropdown";

function CashBalanceGraphTooltip({ active, payload, validUpUntil }) {
    if (!active || payload.length === 0) {
        return null;
    }

    const dataPoint = payload[0].payload;
    const { date, cash_balance_cents, unverified_cash_balance_cents } =
        dataPoint;
    const unverifiedDataPoint =
        cash_balance_cents === undefined &&
        unverified_cash_balance_cents !== undefined;

    return (
        <div className="flex flex-col w-min">
            <div
                className={`w-fit shadow-md bg-white flex flex-col p-[12px] gap-y-[10px] ${
                    unverifiedDataPoint
                        ? "rounded-t-[10px] min-w-[260px]"
                        : "rounded-[10px]"
                }`}
            >
                <div className="text-[12px] font-normal text-flychainGray">
                    {date}
                </div>
                <div>
                    <div className="text-[11px] font-[600] text-flychainPurple-light">
                        Cash Balance
                    </div>
                    <div className="text-[18px] font-[600]">
                        {getCurrencyString(
                            unverifiedDataPoint
                                ? unverified_cash_balance_cents
                                : cash_balance_cents
                        )}
                    </div>
                </div>
            </div>
            {unverifiedDataPoint && (
                <UnverifiedDataWarningDropdown validUpUntil={validUpUntil} />
            )}
        </div>
    );
}

CashBalanceGraphTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(
        PropTypes.shape({
            payload: PropTypes.shape({
                date: PropTypes.string.isRequired,
                cash_balance_cents: PropTypes.number,
                unverified_cash_balance_cents: PropTypes.number,
            }).isRequired,
        }).isRequired
    ),
    validUpUntil: PropTypes.string.isRequired,
};

CashBalanceGraphTooltip.defaultProps = {
    active: false,
    payload: [],
};

export default CashBalanceGraphTooltip;
