import React from "react";
import MainBenefitsCard from "../../MainBenefitsCard";
import FreeWiresAndAchSvg from "./FreeWiresAndAch.svg";

function FreeWiresAndAch() {
    return (
        <MainBenefitsCard
            imageSrc={FreeWiresAndAchSvg}
            title="No Fees For Wires and ACH"
            body="Free domestic and international USD wires and ACH. Make scheduled & recurring payments via ACH, wire, or check."
        />
    );
}

export default FreeWiresAndAch;
