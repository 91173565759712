import React from "react";
import PropTypes from "prop-types";
import SharedCard from "../Card";

function SavingsCard({ title, body, tabText, tabColor }) {
    return (
        <SharedCard showTab tabText={tabText} tabColor={tabColor}>
            <div className="flex flex-col w-[17vw] h-[25vh] gap-y-[20px]">
                <div className="font-semibold text-[14px]">{title}</div>
                <div className="font-normal text-[12px]">{body}</div>
            </div>
        </SharedCard>
    );
}

SavingsCard.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    tabText: PropTypes.string.isRequired,
    tabColor: PropTypes.string.isRequired,
};

export default SavingsCard;
