import React, { useState } from "react";
import PropTypes from "prop-types";
import useClickOutside from "../../hooks/useClickOutside";
import SelectDisplay from "./SelectDisplay";
import SelectDropdown from "./SelectDropdown";

function Select({ label, onChange, value, options, alignment, inline }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const onClickOutside = () => {
        setDropdownOpen(false);
    };

    const containerRef = useClickOutside(onClickOutside);

    const onDisplayClick = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const onOptionClick = (option) => {
        onChange(option);
        setDropdownOpen(false);
    };

    return (
        <div className="inline-block relative">
            <div
                className={`text-[13px] text-flychainGray mb-[5px] font-[600] ${
                    alignment === "left" ? "text-left" : "text-right"
                }`}
            >
                {label}
            </div>
            <div ref={containerRef}>
                <SelectDisplay
                    label={label}
                    value={value}
                    options={options}
                    onClick={onDisplayClick}
                    inline={inline}
                    dropdownOpen={dropdownOpen}
                />
                {dropdownOpen && (
                    <SelectDropdown
                        value={value}
                        options={options}
                        onClick={onOptionClick}
                        alignment={alignment}
                    />
                )}
            </div>
        </div>
    );
}

Select.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    alignment: PropTypes.oneOf(["left", "right"]),
    inline: PropTypes.bool,
};

Select.defaultProps = {
    alignment: "left",
    inline: false,
};

export default Select;
