import React from "react";
import PropTypes from "prop-types";
import CloseSvg from "../../assets/Close.svg";

const getCardPosition = (centerHorizontal, centerVertical) =>
    [centerHorizontal && "justify-center", centerVertical && "items-center"]
        .filter(Boolean) // Remove undefined values
        .join(" ");

function Modal({
    onClose,
    children,
    cardClassName,
    centerHorizontal,
    centerVertical,
}) {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex flex-row bg-black/50 z-[9998] overflow-y-auto scrollbar scrollbar-thumb-flychainPurple-light">
            <div
                className={`flex flex-row w-full p-[20px] ${getCardPosition(
                    centerHorizontal,
                    centerVertical
                )}`}
            >
                <div
                    className={`flex flex-col bg-white rounded-lg p-[25px] ${cardClassName}`}
                >
                    <div className="flex flex-row justify-end">
                        <img
                            className="cursor-pointer h-[15px]"
                            src={CloseSvg}
                            alt="Close"
                            onClick={onClose}
                        />
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    cardClassName: PropTypes.string,
    centerHorizontal: PropTypes.bool,
    centerVertical: PropTypes.bool,
};

Modal.defaultProps = {
    cardClassName: "",
    centerHorizontal: true,
    centerVertical: true,
};

export default Modal;
