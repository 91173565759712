/* eslint-disable camelcase */
import React from "react";
import ImproveFinancialHealth from "./ImproveFinancialHealth";
import ValuationEstimate from "./ValuationEstimate";
import ValuationCall from "./ValuationCall";
import ValuationExplanation from "./ValuationExplanation";
import useGetValuationQuery from "../../hooks/queriesAndMutations/useGetValuationQuery";

function Valuation() {
    const { data: valuationData } = useGetValuationQuery();

    if (!valuationData) return null;

    return (
        <div className="flex flex-col justify-start">
            <ValuationEstimate />
            {valuationData.valuations.length > 0 && <ValuationExplanation />}
            <ValuationCall />
            <ImproveFinancialHealth />
        </div>
    );
}

export default Valuation;
