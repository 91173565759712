import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import useLazyDownloadReportAsyncQuery from "../../../hooks/useLazyDownloadReportAsyncQuery";
import useGetReportType from "../../../hooks/useGetReportType";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import useGetFinancialReportsValidUpUntilQuery from "../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import useValidateDateRange from "../../../hooks/useValidateDateRange";
import { selectDateRange } from "../../../redux/slices/dateRange";
import getReadableReportType from "../utils";
import { YYYY_MM_DD_FORMAT } from "../../../utils";
import REPORT_TYPES from "../constants";
import FormInputField from "../../InputField/FormInputField";
import DateRangePicker from "../../DateRangePicker";
import Button from "../../Button";
import SharedModal from "../../Modal";
import PageTitle from "../../PageTitle";
import LabeledText from "../../LabeledText";
import Label from "../../Label";
import DatePicker from "../../DatePicker";
import UnverifiedDataWarningBanner from "../../UnverifiedDataWarning/UnverifiedDataWarningBanner";
import Checkmark from "../../../assets/Checkmark.svg";

function AsyncModal({ onClose }) {
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        includeIncomplete: false,
    });

    if (!businessEntities) {
        return null;
    }

    return (
        <AsyncModalContent
            onClose={onClose}
            businessEntities={businessEntities}
        />
    );
}

AsyncModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default AsyncModal;

function AsyncModalContent({ onClose, businessEntities }) {
    const reportType = useGetReportType();
    const dateRange = useSelector(selectDateRange);
    const [currentDateRange, setCurrentDateRange] = useState(dateRange);
    const [trigger] = useLazyDownloadReportAsyncQuery();
    const { businessEntityId } = useParams();
    const businessEntity = businessEntities.find(
        (entity) => entity.id === businessEntityId
    );
    const businessEntityName =
        businessEntity.display_name || businessEntity.legal_name;

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    const [currentBalanceSheetEndDate, setCurrentBalanceSheetEndDate] =
        useState(validUpUntilData.valid_up_until);

    const dateRangeUnvalidated = useValidateDateRange(
        validUpUntilData,
        currentDateRange
    );

    const handleDateRangeChange = (newDateRange) => {
        setCurrentDateRange(newDateRange);
    };

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: "onChange",
    });

    const [successMessage, setSuccessMessage] = useState(null);

    const handleFormSubmit = async (data) => {
        try {
            setSuccessMessage(null);

            const { email } = data;

            const { startDate } = currentDateRange;
            let { endDate } = currentDateRange;
            if (reportType === REPORT_TYPES.BALANCE_SHEET) {
                endDate = currentBalanceSheetEndDate;
            }

            await trigger({
                startDate,
                endDate,
                email,
            });

            setSuccessMessage(
                "The report will be sent to your email shortly! If you don't receive an email within 5 minutes, please try downloading the report again."
            );
        } catch (error) {
            console.error("Error downloading report:", error);
            setSuccessMessage(null);
        }
    };

    if (!validUpUntilData) {
        return null;
    }

    return (
        <SharedModal
            onClose={onClose}
            cardClassName="h-fit min-w-[500px]"
            centerVertical={false}
        >
            <div className="flex flex-col gap-y-[24px] max-w-[550px]">
                <PageTitle>{`Download ${getReadableReportType(
                    reportType
                )}`}</PageTitle>
                <LabeledText
                    label="Business Entity Name"
                    text={businessEntityName}
                />
                <div>
                    {reportType === REPORT_TYPES.BALANCE_SHEET ? (
                        <>
                            <div className="mb-[8px]">
                                <Label>Please confirm the end date</Label>
                            </div>
                            <DatePicker
                                date={currentBalanceSheetEndDate}
                                onChange={setCurrentBalanceSheetEndDate}
                                maxDate={validUpUntilData.valid_up_until}
                            />
                        </>
                    ) : (
                        <>
                            <div className="mb-[8px]">
                                <Label>Please confirm the date range</Label>
                            </div>
                            <DateRangePicker
                                dateRange={currentDateRange}
                                onChange={handleDateRangeChange}
                                maxDate={dayjs().format(YYYY_MM_DD_FORMAT)}
                                validUpUntil={validUpUntilData.valid_up_until}
                            />
                        </>
                    )}
                </div>
                {dateRangeUnvalidated && (
                    <div>
                        <UnverifiedDataWarningBanner
                            validUpUntil={validUpUntilData.valid_up_until}
                        />
                    </div>
                )}
                <div className="mb-[8px]">
                    <div className="mb-[8px]">
                        <Label>We&apos;ll send the report to this email</Label>
                    </div>
                    <div className="w-1/2">
                        <FormInputField
                            className="p-[8px] w-full rounded-full border-[2px] border-gray-300 focus:outline-flychainPurple-light"
                            name="email"
                            type="email"
                            placeholder="Enter your email"
                            validation={{
                                required: "Email is required",
                                pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: "Invalid email address",
                                },
                            }}
                            register={register}
                            error={errors.email}
                            showError={false}
                        />
                    </div>
                </div>
                <div className="flex flex-row mr-[32px]">
                    <div className="mr-[16px]">
                        <Button
                            onClick={handleSubmit(handleFormSubmit)}
                            disabled={!isValid}
                        >
                            Download
                        </Button>
                    </div>
                    <Button color="secondary" onClick={onClose}>
                        Done
                    </Button>
                </div>
                {successMessage && (
                    <div className="flex flex-row items-start mt-[16px]">
                        <img
                            className="w-[20px] mr-[8px]"
                            src={Checkmark}
                            alt="Checkmark"
                        />
                        <div className="flex flex-col">
                            <p className="text-[14px] font-bold text-flychainPurple-light">
                                The report will be sent to your email shortly!
                            </p>
                            <p className="text-[14px] font-medium text-flychainPurple-dark">
                                If you don&apos;t receive an email within 5
                                minutes, please try downloading the report
                                again.
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </SharedModal>
    );
}

AsyncModalContent.propTypes = {
    onClose: PropTypes.func.isRequired,
    businessEntities: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};
