import React from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "../../Select";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";

function BusinessEntitySelect({ inline }) {
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        includeIncomplete: false,
    });

    const { businessEntityId } = useParams();

    const location = useLocation();

    const navigate = useNavigate();

    const onChange = (option) => {
        const newPath = location.pathname.replace(
            /\/businessEntity\/[^/]+/, // This regex matches /businessEntity/:businessEntityId
            `/businessEntity/${option.value}`
        );
        navigate(newPath, { replace: true });
    };

    if (!businessEntities || businessEntities.length <= 1) return null;

    return (
        <div
            className={
                inline
                    ? "flex flex-row gap-x-[20px] w-fit"
                    : "fixed top-5 right-[60px] z-[1]"
            }
        >
            <Select
                label="Business Entity"
                value={businessEntityId}
                onChange={onChange}
                options={businessEntities.map((businessEntity) => ({
                    value: businessEntity.id,
                    label:
                        businessEntity.display_name ||
                        businessEntity.legal_name,
                }))}
                alignment="right"
                inline={inline}
            />
        </div>
    );
}

BusinessEntitySelect.propTypes = {
    inline: PropTypes.bool,
};

BusinessEntitySelect.defaultProps = {
    inline: false,
};

export default BusinessEntitySelect;
