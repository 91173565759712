import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SelectBusinessEntity from "../../SelectBusinessEntity";
import LiveAdvancedPaymentOnClaims from "./LiveAdvancedPaymentOnClaims";
import useGetLineForBusinessEntityQuery from "../../../hooks/queriesAndMutations/useGetLineForBusinessEntityQuery";
import useGetPartnerQuery from "../../../hooks/queriesAndMutations/useGetPartnerQuery";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import Landing from "./Landing";
import VayaLanding from "./VayaLanding";
import PARTNERS from "../../../partners/constants";

function AdvancedPaymentLandingRouter({ partner, businessEntityId }) {
    const { data: line, isFetching } = useGetLineForBusinessEntityQuery({
        businessEntityId,
    });

    if (!line || isFetching) {
        return null;
    }

    if (partner.internal_name === PARTNERS.HEADSTART) {
        return <VayaLanding />;
    }

    if (line.id) {
        return (
            <LiveAdvancedPaymentOnClaims businessEntityId={businessEntityId} />
        );
    }

    return <Landing />;
}

AdvancedPaymentLandingRouter.propTypes = {
    partner: PropTypes.object.isRequired,
    businessEntityId: PropTypes.string.isRequired,
};

function AdvancedPaymentOnClaims() {
    const [businessEntityId, setBusinessEntityId] = useState(null);
    const { data: partner } = useGetPartnerQuery();
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        includeIncomplete: true,
    });

    useEffect(() => {
        if (businessEntities && businessEntities.length > 0) {
            setBusinessEntityId(businessEntities[0].id);
        }
    }, [businessEntities]);

    if (!partner || !businessEntities || !businessEntityId) {
        return null;
    }

    const onBusinessEntityChange = (option) => {
        setBusinessEntityId(option.value);
    };

    return (
        <div>
            {businessEntities.length > 1 && (
                <SelectBusinessEntity
                    businessEntities={businessEntities}
                    businessEntityId={businessEntityId}
                    onChange={onBusinessEntityChange}
                />
            )}
            <AdvancedPaymentLandingRouter
                partner={partner}
                businessEntityId={businessEntityId}
            />
        </div>
    );
}

export default AdvancedPaymentOnClaims;
