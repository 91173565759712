import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

// This is needed to parse the month year string in Safari
dayjs.extend(customParseFormat);

export const formatMonthYearStringForXAxis = (monthYearString) => {
    const date = dayjs(monthYearString, ["MMMM YYYY", "MMM YYYY"]);
    return date.format("MMM 'YY");
};

export const tickStyle = {
    fill: "black",
    fontSize: 10,
    fontWeight: 400,
};

export const tickFormatter = (tick) => {
    const date = dayjs(tick, "MMM DD YYYY");
    return date.format("MMM 'YY");
};

// Enum for valid date string formats
export const VALID_DATE_FORMATS = {
    MMMM_D_YYYY: "MMMM D, YYYY",
    MMM_D_YYYY: "MMM D, YYYY",
    MMMM_YYYY: "MMMM YYYY",
    MMM_YYYY: "MMM YYYY",
};

// This function checks if the date is after the validUpUntil date
export const isAfterValidDate = (dateStr, format, validUpUntil) => {
    if (!Object.values(VALID_DATE_FORMATS).includes(format)) {
        throw new Error("Invalid date format");
    }

    const validDate = dayjs(validUpUntil);
    let month;
    let year;
    let day;

    // If the format is a date with a day, then we need to split the dateStr by space into the 3 components
    if (
        format === VALID_DATE_FORMATS.MMMM_D_YYYY ||
        format === VALID_DATE_FORMATS.MMM_D_YYYY
    ) {
        [month, day, year] = dateStr.split(" ");
    } else {
        // If the format is a date without a day (i.e. specifies only a month and year), then we need to split the dateStr by space into the 2 components to get the month and year
        [month, year] = dateStr.split(" ");
        day = dayjs(`${month} 1, ${year}`).daysInMonth(); // We set the day to last day of month because we want to check if any part of the month is after the validUpUntil date
    }

    const dataDate = dayjs(`${month} ${day}, ${year}`);

    return dataDate.isAfter(validDate, "day");
};

export const splitMetric = (value, dateStr, format, validUpUntil) => ({
    verified: isAfterValidDate(dateStr, format, validUpUntil)
        ? undefined
        : value,
    unverified: isAfterValidDate(dateStr, format, validUpUntil)
        ? value
        : undefined,
});
