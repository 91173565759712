import React, { useState } from "react";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ExpensesByCategory from "./ExpensesByCategory";
import HorizontalTabs from "../../HorizontalTabs";
import ExpensesByMerchant from "./ExpensesByMerchant";
import Badge from "../../Badge";
import useIsAdminDashboard from "../../../hooks/useIsAdminDashboard";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import StackedBarGraph from "./StackedBarGraph";
import SectionTitle from "../../SectionTitle";
import PageTitle from "../../PageTitle";
import ValidUpUntil from "../ValidUpUntil";

function Expenses() {
    /*
        TODO: have to figure out how to deal with back navigation
        i.e. when the user goes to MerchantDetailView and then clicks back,
        the router forgets that By Merchant was the active tab.
        The fix likely involves storing a query param in the url.
    */
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const tabs = [
        "By Category",
        <div className="flex flex-row items-center gap-x-[10px]">
            <span>By Merchant</span>
            <Badge color="green">
                <div className="text-xs font-normal">Beta</div>
            </Badge>
        </div>,
    ];

    const isAdmin = useIsAdminDashboard();

    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        includeIncomplete: false,
    });

    if (!businessEntities) return null;

    const multipleBusinessEntities = businessEntities.length > 1;

    return (
        <div className="flex flex-col px-[60px] pb-[60px] pt-[30px] gap-y-[30px]">
            {multipleBusinessEntities ? (
                <div className="flex flex-row gap-x-[30px]">
                    <ValidUpUntil />
                    <BusinessEntitySelect inline />
                </div>
            ) : (
                <ValidUpUntil />
            )}
            <div className="flex flex-col gap-y-[30px]">
                <PageTitle>Expenses By Category</PageTitle>
                <div className="flex flex-col gap-y-[20px]">
                    <SectionTitle>By Month</SectionTitle>
                    <StackedBarGraph />
                </div>
                <div className="flex flex-col gap-y-[20px]">
                    <SectionTitle>For Date Range</SectionTitle>
                    {
                        // TODO: remove the conditional code below once we've refined ExpensesByMerchant
                        isAdmin ? (
                            <>
                                <HorizontalTabs
                                    tabs={tabs}
                                    onChange={setActiveTabIndex}
                                />
                                {activeTabIndex === 0 ? (
                                    <ExpensesByCategory />
                                ) : (
                                    <ExpensesByMerchant />
                                )}
                            </>
                        ) : (
                            <ExpensesByCategory />
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default Expenses;
