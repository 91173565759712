import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { YYYY_MM_DD_FORMAT } from "../../../../../../../utils";

const shouldDisableDate = (date) => {
    const firstDayOfMonth = date.startOf("month").date();
    return date.date() !== firstDayOfMonth;
};

function EntriesStartPicker({ value, onChange }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label="Select Entries Start Date"
                value={dayjs(value)}
                onChange={(date) => onChange(date.format(YYYY_MM_DD_FORMAT))}
                shouldDisableDate={shouldDisableDate}
                slotProps={{ textField: { size: "small" } }}
            />
        </LocalizationProvider>
    );
}

EntriesStartPicker.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default EntriesStartPicker;
