import React from "react";
import PropTypes from "prop-types";

function GettingStartedBullet({ number, description }) {
    return (
        <div className="flex flex-row gap-x-[16px]">
            <div className="w-[24px] h-[24px] flex flex-col justify-center items-center bg-flychainPurple-light rounded-[50%] text-white font-[400] text-[12px] ">
                {number}
            </div>
            <div className="flex-[1] text-[14px] font-[400]">{description}</div>
        </div>
    );
}

GettingStartedBullet.propTypes = {
    number: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
};

export default GettingStartedBullet;
